/* eslint-disable */
export const songs = [
  {
    "artist": "blink-182",
    "title": "Miss You",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I miss you, I miss you"
      },
      {
        "label": "",
        "index": 1,
        "text": "Hello, there\nThe angel from my nightmare\nThe shadow in the background of the morgue\nThe unsuspecting victim"
      },
      {
        "label": "",
        "index": 2,
        "text": "Of darkness in the valley\nWe can live like Jack and Sally if we want\nWhere you can always find me"
      },
      {
        "label": "",
        "index": 3,
        "text": "And we'll have Halloween on Christmas\nAnd in the night, we'll wish this never ends\nWe'll wish this never ends"
      },
      {
        "label": "",
        "index": 4,
        "text": "Where are you?\nAnd I'm so sorry\nI cannot sleep, I cannot dream tonight\nI need somebody and always"
      },
      {
        "label": "",
        "index": 5,
        "text": "This sick, strange darkness\nComes creeping on, so haunting every time\nAnd as I stare, I counted\nThe webs from all the spiders\nCatching things and eating their insides"
      },
      {
        "label": "",
        "index": 6,
        "text": "Like indecision to call you\nAnd hear your voice of treason\nWill you come home and stop this pain tonight?\nStop this pain tonight"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "Don't waste your time on me,\nyou're already\nThe voice inside my head\n(I miss you, I miss you)"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "Don't waste your time on me,\nyou're already\nThe voice inside my head"
      },
      {
        "label": "",
        "index": 9,
        "text": "I miss you, I miss you\nI miss you, I miss you\nI miss you, I miss you\nI miss you, I miss you"
      }
    ],
    "id": "blink-182-miss-you",
    "capo": 4,
    "chords": [
      {
        "label": "Verse",
        "value": "G A7sus4 Em7",
        "chords": [
          "G",
          "A7sus4",
          "Em7"
        ]
      },
      {
        "label": "Chorus",
        "value": "G A7sus4 B_B182 Dsus4 Cadd9",
        "chords": [
          "G",
          "A7sus4",
          "B_B182",
          "Dsus4",
          "Cadd9"
        ]
      },
      {
        "label": "Bridge",
        "value": "Em7 A9sus4/13 G",
        "chords": [
          "Em7",
          "A9sus4/13",
          "G"
        ]
      }
    ]
  },
  {
    "artist": "blink-182",
    "title": "ONE MORE TIME",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Strangers, from strangers into brothers\nFrom brothers into strangers once again\nWe saw the whole world, but I couldn't see the meaning\nI couldn't even recognize my friends"
      },
      {
        "label": "",
        "index": 1,
        "text": "Older, but nothing's any different\nRight now feels the same, I wonder why\nI wish they told us, it shouldn't take a sickness\nOr airplanes falling out the sky"
      },
      {
        "label": "",
        "index": 2,
        "text": "Do I have to die to hear you miss me?\nDo I have to die to hear you say goodbye?\nI don't wanna act like there's tomorrow\nI don't wanna wait to do this one more time"
      },
      {
        "label": "",
        "index": 3,
        "text": "One morе time\nOne more\nOnе more time\nOne more time"
      },
      {
        "label": "",
        "index": 4,
        "text": "I miss you, took time, but I admit it\nIt still hurts even after all these years\nAnd I know that next time ain't always gonna happen\nI gotta say \"I love you\" while we're here"
      },
      {
        "label": "",
        "index": 5,
        "text": "Do I have to die to hear you miss me?\nDo I have to die to hear you say goodbye?\nI don't wanna act like there's tomorrow\nI don't wanna wait to do this one more time"
      },
      {
        "label": "",
        "index": 6,
        "text": "One more time\nI miss you"
      }
    ],
    "id": "blink-182-one-more-time",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Cranberries",
    "title": "Linger",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "If you, if you could return\nDon't let it burn\nDon't let it fade\nI'm sure I'm not being rude"
      },
      {
        "label": "",
        "index": 1,
        "text": "But it's just your attitude\nIt's tearing me apart\nIt's ruining every day"
      },
      {
        "label": "",
        "index": 2,
        "text": "I swore, I swore I would be true\nAnd honey so did you\nSo why were you holding her hand?\nIs that the way we stand?\nWere you lying all the time?\nWas it just a game to you?"
      },
      {
        "label": "",
        "index": 3,
        "text": "But I'm in so deep\nYou know I'm such a fool for you\nYou got me wrapped around your finger\nDo you have to let it linger?\nDo you have to\nDo you have to\nDo you have to let it linger?"
      },
      {
        "label": "",
        "index": 4,
        "text": "Oh, I thought the world of you\nI thought nothing could go wrong\nBut I was wrong, I was wrong"
      },
      {
        "label": "",
        "index": 5,
        "text": "If you, if you could get by\nTrying not to lie\nThings wouldn't be so confused\nAnd I wouldn't feel so used\nBut you always really knew\nI just want to be with you"
      },
      {
        "label": "",
        "index": 6,
        "text": "And I'm in so deep\nYou know I'm such a fool for you\nYou got me wrapped around your finger\nDo you have to let it linger?\nDo you have to\nDo you have to\nDo you have to let it linger?"
      },
      {
        "label": "",
        "index": 7,
        "text": "And I'm in so deep\nYou know I'm such a fool for you\nYou got me wrapped around your finger\nDo you have to let it linger?\nDo you have to\nDo you have to\nDo you have to let it linger?"
      },
      {
        "label": "",
        "index": 8,
        "text": "You know I'm such a fool for you\nYou got me wrapped around your finger\nDo you have to let it linger?\nDo you have to\nDo you have to\nDo you have to let it linger?"
      }
    ],
    "id": "cranberries-linger",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Daphne Loves Derby",
    "title": "Simple, Starving To Be Safe",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "Lately I've been thinking\nso strangely about the clouds\nAnd how they seem to slowly fade away, yeah\nMaybe someday we will find a way to disappear\nJust me and you on silver lining dreams"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Oh, how could I survive\nWithout your love and the hope you bring?\nOh, even when the sky is falling down\nI know I have you, and it's all I need"
      },
      {
        "label": "Verse",
        "index": 2,
        "text": "Lately I've been hoping you can stay with me\nAnd I could hold you close 'til the end of time, yeah\nMaybe someday we will grab some change\nand run away\nBut for now I'll learn to say goodbye"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Oh, how could I survive\nWithout your love and the hope you bring?\nOh, even when the world is breaking down\nI know I have you, and it's all I need"
      },
      {
        "label": "Bridge",
        "index": 4,
        "text": "Oh, she is my everything\nOh, she is all I need\nOh, she is my everything\nOh, she is all I need"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Oh, how could I survive\nWithout your love and the hope you bring?\nOh, even when the world just falls apart\nI know I have you, and it's all I need"
      }
    ],
    "id": "daphne-loves-derby-simple-starving-to-be-safe",
    "capo": 4,
    "chords": [
      {
        "label": "Intro",
        "value": "Fmaj7 Fmadd9 C_DLD Cmaj7",
        "chords": [
          "Fmaj7",
          "Fmadd9",
          "C_DLD",
          "Cmaj7"
        ]
      },
      {
        "label": "Verse",
        "value": "Fmaj7 C",
        "chords": [
          "Fmaj7",
          "C"
        ]
      },
      {
        "label": "Chorus",
        "value": "E Am Dsus2 G",
        "chords": [
          "E",
          "Am",
          "Dsus2",
          "G"
        ]
      }
    ]
  },
  {
    "artist": "David Bowie",
    "title": "Heroes",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I, I wish you could swim\nLike the dolphins, like dolphins can swim"
      },
      {
        "label": "",
        "index": 1,
        "text": "Though nothing, nothing will keep us together\nWe can beat them forever and ever\nOh, we can be heroes just for one day"
      },
      {
        "label": "",
        "index": 2,
        "text": "I, I will be king\nAnd you, you will be queen\nThough nothing will drive them away"
      },
      {
        "label": "",
        "index": 3,
        "text": "We can be heroes just for one day\nWe can be us just for one day"
      },
      {
        "label": "",
        "index": 4,
        "text": "I, I can remember (I remember)\nStanding by the wall (By the wall)\nAnd the guns shot above our heads (Over our heads)\nAnd we kissed as though nothing could fall (Nothing could fall)"
      },
      {
        "label": "",
        "index": 5,
        "text": "And the shame was on the other side\nOh, we can beat them forever and ever\nThen we could be heroes just for one day"
      },
      {
        "label": "",
        "index": 6,
        "text": "We can be heroes\nWe can be heroes\nWe can be heroes just for one day\nWe can be heroes"
      }
    ],
    "id": "david-bowie-heroes",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "David Bowie",
    "title": "The Man Who Sold the World",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "We passed upon the stair\nWe spoke of was and when\nAlthough I wasn't there\nHe said I was his friend\nWhich came as a surprise\nI spoke into his eyes\nI thought you died alone\nA long, long time ago"
      },
      {
        "label": "",
        "index": 1,
        "text": "Oh no, not me\nWe never lost control\nYou're face to face\nWith the man who sold the world"
      },
      {
        "label": "",
        "index": 2,
        "text": "I laughed and shook his hand\nAnd made my way back home\nI searched for form and land\nFor years and years, I roamed\nI gazed a gazeless stare\nWe walked a million hills\nI must have died alone\nA long, long time ago"
      },
      {
        "label": "",
        "index": 3,
        "text": "Who knows?\nNot me\nI never lost control\nYou're face to face\nWith the man who sold the world"
      },
      {
        "label": "",
        "index": 4,
        "text": "Who knows?\nNot me\nWe never lost control\nYou're face to face\nWith the man who sold the world"
      }
    ],
    "id": "david-bowie-the-man-who-sold-the-world",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Death Cab for Cutie",
    "title": "I Will Follow You into the Dark",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Love of mine\nSomeday you will die\nBut I'll be close behind\nI'll follow you into the dark"
      },
      {
        "label": "",
        "index": 1,
        "text": "No blinding light\nOr tunnels, to gates of white\nJust our hands clasped so tight\nWaiting for the hint of a spark"
      },
      {
        "label": "",
        "index": 2,
        "text": "If Heaven and Hell decide that they both are satisfied\nIlluminate the no's on their vacancy signs\nIf there's no one beside you when your soul embarks\nThen I'll follow you into the dark"
      },
      {
        "label": "",
        "index": 3,
        "text": "In Catholic school, as vicious as Roman rule\nI got my knuckles bruised by a lady in black\nAnd I held my tongue as she told me\n\"Son, fear is the heart of love,\" so I never went back"
      },
      {
        "label": "",
        "index": 4,
        "text": "And if Heaven and Hell decide that they both are satisfied\nIlluminate the no's on their vacancy signs\nIf there's no one beside you when your soul embarks\nThen I'll follow you into the dark"
      },
      {
        "label": "",
        "index": 5,
        "text": "You and me have seen everything to see\nFrom Bangkok to Calgary\nAnd the soles of your shoes are all worn down\nThe time for sleep is now\nBut it's nothing to cry about\n'Cause we'll hold each other soon\nIn the blackest of rooms"
      },
      {
        "label": "",
        "index": 6,
        "text": "And if Heaven and Hell decide that they both are satisfied\nIlluminate the no's on their vacancy signs\nIf there's no one beside you when your soul embarks\nThen I'll follow you into the dark"
      }
    ],
    "id": "death-cab-for-cutie-i-will-follow-you-into-the-dark",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Death Cab for Cutie",
    "title": "Soul Meets Body",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I want to live where soul meets body\nAnd let the sun wrap its arms around me and\nBathe my skin in water cool and cleansing\nAnd feel\nFeel what it's like to be new"
      },
      {
        "label": "",
        "index": 1,
        "text": "'Cause in my head there is a Greyhound station\nWhere I send my thoughts to far off destinations\nSo they may have a chance of finding a place where they're\nFar more suited than here"
      },
      {
        "label": "",
        "index": 2,
        "text": "Ba-da-ba-da-ba-ba, ba-da-ba-ba, ba-da-ba-ba\nBa-da-ba-ba, ba-da-ba-ba-da-ba-da-ba"
      },
      {
        "label": "",
        "index": 3,
        "text": "I cannot guess what we'll discover\nWhen we turn the dirt with our palms cupped like shovels\nBut I know our filthy hands can wash one another's\nAnd not one speck will remain"
      },
      {
        "label": "",
        "index": 4,
        "text": "I do believe it's true\nThat there are roads left in both of our shoes\nBut if the silence takes you\nThen I hope it takes me too"
      },
      {
        "label": "",
        "index": 5,
        "text": "So brown eyes I'll hold you near\n'Cause you're the only song I want to hear\nA melody softly soaring through my atmosphere"
      },
      {
        "label": "",
        "index": 6,
        "text": "Where soul meets body\nWhere soul meets body\nWhere soul meets body"
      },
      {
        "label": "",
        "index": 7,
        "text": "And I do believe it's true\nThat there are roads left in both of our shoes\nBut if the silence takes you\nThen I hope it takes me too"
      },
      {
        "label": "",
        "index": 8,
        "text": "So brown eyes I'll hold you near\n'Cause you're the only song I want to hear\nA melody softly soaring through my atmosphere"
      },
      {
        "label": "",
        "index": 9,
        "text": "A melody softly soaring through my atmosphere\nA melody softly soaring through my atmosphere"
      }
    ],
    "id": "death-cab-for-cutie-soul-meets-body",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Death Cab for Cutie",
    "title": "Title and Registration",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "The glove compartment is inaccurately named\nAnd everybody knows it\nSo I'm proposing a swift orderly change"
      },
      {
        "label": "",
        "index": 1,
        "text": "'Cause behind its door, there's nothing to keep my fingers warm\nAnd all I find are souvenirs from better times\nBefore the gleam of your taillights fading east\nTo find yourself a better life"
      },
      {
        "label": "",
        "index": 2,
        "text": "I was searching for some legal document\nAs the rain beat down on the hood\nWhen I stumbled upon pictures I tried to forget\nAnd that's how this idea was drilled into my head\n'Cause it's too important to stay the way it's been"
      },
      {
        "label": "",
        "index": 3,
        "text": "But there's no blame for how our love did slowly fade\nAnd now that it's gone, it's like it wasn't there at all\nAnd here I rest where disappointment and regret collide\nLying awake at night"
      },
      {
        "label": "",
        "index": 4,
        "text": "And there's no blame for how our love did slowly fade\nAnd now that it's gone, it's like it wasn't there at all\nAnd here I rest where disappointment and regret collide\nLying awake at night, up all night"
      }
    ],
    "id": "death-cab-for-cutie-title-and-registration",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Death Cab for Cutie",
    "title": "Your Heart Is an Empty Room",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Burn it down 'til the embers smoke on the ground\nAnd start new when your heart is an empty room\nWith walls of the deepest blue"
      },
      {
        "label": "",
        "index": 1,
        "text": "Home's face\nHow it ages when you're away\nAnd spring blooms \nand you find a love that's true\nBut you don't know what now to do\n'Cause the chase is all you know\nand she stopped running months ago"
      },
      {
        "label": "",
        "index": 2,
        "text": "And all you see is where else you could be\nwhen you're at home\nAnd out on the street\nare so many possibilities to not be alone"
      },
      {
        "label": "",
        "index": 3,
        "text": "The flames and smoke climbed out of every window\nAnd disappeared with everything that you held dear\nBut you shed not a single tear\nFor the things that you didn't need\n'Cause you knew you were finally free"
      },
      {
        "label": "",
        "index": 4,
        "text": "'Cause all you see is where else\nyou could be when you're at home\nAnd out on the street\nare so many possibilities to not be alone"
      }
    ],
    "id": "death-cab-for-cutie-your-heart-is-an-empty-room",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Face to Face",
    "title": "All For Nothing",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I have given up the demons\nI've made up a hundred reasons\nI have turned my back on everything I knew"
      },
      {
        "label": "",
        "index": 1,
        "text": "I have justified my actions\nBeen denying satisfaction\nI believe tonight\nI've pulled away the truth"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "All to be with you"
      },
      {
        "label": "",
        "index": 3,
        "text": "I have lost the inhibitions\nI have felt a deep contrition\nAnd I've realized there's still\nso much to do"
      },
      {
        "label": "",
        "index": 4,
        "text": "I was lost inside my own lies\nI felt victim and I thought I\nWould never find the strength\nto do what I must do"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "All to be with you"
      },
      {
        "label": "",
        "index": 6,
        "text": "I've been inside hell and out of\nI've done things I'm less than proud of\nI have only ever tried to get to you"
      },
      {
        "label": "",
        "index": 7,
        "text": "I've lost everything and still have\nNever given up until I either die\nOr I will make it back to you"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "All to be with you"
      }
    ],
    "id": "face-to-face-all-for-nothing",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Foo Fighters",
    "title": "Everlong",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Hello\nI've waited here for you\nEverlong\nTonight\nI throw myself into\nAnd out of the red\nOut of her head she sang"
      },
      {
        "label": "",
        "index": 1,
        "text": "Come down\nAnd waste away with me\nDown with me\nSlow how\nYou wanted it to be\nI'm over my head\nOut of her head she sang"
      },
      {
        "label": "Pre Chorus",
        "index": 2,
        "text": "And I wonder\nWhen I sing along with you"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "If everything could ever feel this real forever\nIf anything could ever be this good again\nThe only thing I'll ever ask of you\nYou gotta promise not to stop when I say when she sang"
      },
      {
        "label": "",
        "index": 4,
        "text": "Breathe out\nSo I can breathe you in\nHold you in\nAnd now\nI know you've always been\nOut of your head\nOut of my head I sang"
      },
      {
        "label": "Pre Chorus",
        "index": 5,
        "text": "And I wonder\nWhen I sing along with you"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "If everything could ever feel this real forever\nIf anything could ever be this good again\nThe only thing I'll ever ask of you\nYou gotta promise not to stop when I say when she sang"
      },
      {
        "label": "Pre Chorus",
        "index": 7,
        "text": "And I wonder\nWhen I sing along with you"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "If everything could ever feel this real forever\nIf anything could ever be this good again\nThe only thing I'll ever ask of you\nYou gotta promise not to stop when I say when"
      }
    ],
    "id": "foo-fighters-everlong",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Foo Fighters",
    "title": "Learn to Fly",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Run and tell all of the angels\nThis could take all night\nThink I need a devil to help me get things right"
      },
      {
        "label": "",
        "index": 1,
        "text": "Hook me up a new revolution\n'Cause this one is a lie\nWe sat around laughing and watched the last one die"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Now I'm looking to the sky to save me\nLooking for a sign of life\nLooking for something to help me burn out bright\nI'm looking for a complication\nLooking 'cause I'm tired of lying\nMake my way back home when I learn to fly high."
      },
      {
        "label": "",
        "index": 3,
        "text": "I think I'm done nursing the patience\nIt can wait one night\nI'd give it all away if you give me one last try"
      },
      {
        "label": "",
        "index": 4,
        "text": "We'll live happily ever trapped if you just save my life\nRun and tell the angels that everything's alright"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Now I'm looking to the sky to save me\nLooking for a sign of life\nLooking for something to help me burn out bright\nI'm looking for a complication\nLooking 'cause I'm tired of lying\nMake my way back home when I learn to fly high\nMake my way back home when I learn to..."
      },
      {
        "label": "Bridge",
        "index": 6,
        "text": "...fly along with me, I can't quite make it alone\nTry to make this life my own\nFly along with me, I can't quite make it alone\nTry to make this life my own"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "Now I'm looking to the sky to save me\nLooking for a sign of life\nLooking for something to help me burn out bright\nI'm looking for a complication\nLooking 'cause I'm tired of trying\nMake my way back home when I learn to"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "Now I'm looking to the sky to save me\nLooking for a sign of life\nLooking for something to help me burn out bright\nI'm looking for a complication\nLooking 'cause I'm tired of trying\nMake my way back home when I learn to fly"
      },
      {
        "label": "",
        "index": 9,
        "text": "Make my way back home when I learn to fly\nMake my way back home when I learn to"
      }
    ],
    "id": "foo-fighters-learn-to-fly",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Foo Fighters",
    "title": "My Hero",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Too alarming now to talk about\nTake your pictures down and shake it out\nTruth or consequence, say it aloud\nUse that evidence, race it around"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "There goes my hero\nWatch him as he goes\nThere goes my hero\nHe's ordinary"
      },
      {
        "label": "",
        "index": 2,
        "text": "Don't the best of them bleed it out\nWhile the rest of them peter out\nTruth or consequence, say it aloud\nUse that evidence, race it around"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "There goes my hero\nWatch him as he goes\nThere goes my hero\nHe's ordinary"
      },
      {
        "label": "",
        "index": 4,
        "text": "Kudos, my hero\nLeaving all the best\nYou know my hero\nThe one that's on"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "There goes my hero\nWatch him as he goes\nThere goes my hero\nHe's ordinary"
      }
    ],
    "id": "foo-fighters-my-hero",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Gotye",
    "title": "Somebody I Used To Know",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Now and then I think of when we were together\nLike when you said you felt so happy you could die\nTold myself that you were right for me\nBut felt so lonely in your company\nBut that was love, and it's an ache I still remember"
      },
      {
        "label": "",
        "index": 1,
        "text": "You can get addicted to a certain kind of sadness\nLike resignation to the end, always the end\nSo when we found that we could not make sense\nWell, you said that we would still be friends\nBut I'll admit that I was glad it was over"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "But you didn't have to cut me off\nMake out like it never happened and that we were nothing\nAnd I don't even need your love\nBut you treat me like a stranger, and that feels so rough"
      },
      {
        "label": "",
        "index": 3,
        "text": "No, you didn't have to stoop so low\nHave your friends collect your records\nAnd then change your number\nI guess that I don't need that, though\nNow you're just somebody that I used to know (3x)"
      },
      {
        "label": "",
        "index": 4,
        "text": "Now and then I think of all the times you screwed me over\nBut had me believing it was always something that I'd done\nAnd I don't wanna live that way\nReading into every word you say\nYou said that you could let it go\nAnd I wouldn't catch you hung up on somebody that you used to know"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "But you didn't have to cut me off\nMake out like it never happened and that we were nothing (aah-ooh)\nAnd I don't even need your love (ooh)\nBut you treat me like a stranger, and that feels so rough (aah)"
      },
      {
        "label": "",
        "index": 6,
        "text": "No, you didn't have to stoop so low (ooh)\nHave your friends collect your records\nAnd then change your number (aah)\nI guess that I don't need that, though\nNow you're just somebody that I used to know"
      },
      {
        "label": "",
        "index": 7,
        "text": "Somebody (I used to know)\nSomebody (now you're just somebody that I used to know)\nSomebody (I used to know)\nSomebody (now you're just somebody that I used to know)\nI used to know\nThat I used to know\nI used to know\nSomebody"
      }
    ],
    "id": "gotye-somebody-i-used-to-know",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Green Day",
    "title": "Broken Dreams",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I walk a lonely road\nThe only one that I have ever known\nDon't know where it goes\nBut it's home to me, and I walk alone"
      },
      {
        "label": "",
        "index": 1,
        "text": "I walk this empty street\nOn the Boulevard of Broken Dreams\nWhere the city sleeps\nAnd I'm the only one, and I walk alone"
      },
      {
        "label": "",
        "index": 2,
        "text": "I walk alone, I walk alone\nI walk alone, I walk a-"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "My shadow's the only one that walks beside me\nMy shallow heart's the only thing that's beating\nSometimes, I wish someone out there will find me\n'Til then, I walk alone"
      },
      {
        "label": "",
        "index": 4,
        "text": "I'm walking down the line\nThat divides me somewhere in my mind\nOn the borderline\nOf the edge, and where I walk alone"
      },
      {
        "label": "",
        "index": 5,
        "text": "Read between the lines\nWhat's fucked up, and everything's alright\nCheck my vital signs\nTo know I'm still alive, and I walk alone"
      },
      {
        "label": "",
        "index": 6,
        "text": "I walk alone, I walk alone\nI walk alone, I walk a-"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "My shadow's the only one that walks beside me\nMy shallow heart's the only thing that's beating\nSometimes, I wish someone out there will find me\n'Til then, I walk alone"
      },
      {
        "label": "",
        "index": 8,
        "text": "I walk this empty street\nOn the Boulevard of Broken Dreams\nWhere the city sleeps\nAnd I'm the only one, and I walk a-"
      },
      {
        "label": "Chorus",
        "index": 9,
        "text": "My shadow's the only one that walks beside me\nMy shallow heart's the only thing that's beating\nSometimes, I wish someone out there will find me\n'Til then, I walk alone"
      }
    ],
    "id": "green-day-broken-dreams",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Green Day",
    "title": "Good Riddance",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Fuck..."
      },
      {
        "label": "",
        "index": 1,
        "text": "Another turning point, a fork stuck in the road\nTime grabs you by the wrist, directs you where to go\nSo make the best of this test, and don't ask why\nIt's not a question, but a lesson learned in time"
      },
      {
        "label": "",
        "index": 2,
        "text": "It's something unpredictable, but in the end is right\nI hope you had the time of your life"
      },
      {
        "label": "",
        "index": 3,
        "text": "So take the photographs, and still frames in your mind\nHang it on a shelf in good health and good time\nTattoos of memories and dead skin on trial\nFor what it's worth it was worth all the while"
      },
      {
        "label": "",
        "index": 4,
        "text": "It's something unpredictable, but in the end is right\nI hope you had the time of your life"
      },
      {
        "label": "",
        "index": 5,
        "text": "It's something unpredictable, but in the end is right\nI hope you had the time of your life"
      },
      {
        "label": "",
        "index": 6,
        "text": "It's something unpredictable, but in the end is right\nI hope you had the time of your life"
      }
    ],
    "id": "green-day-good-riddance",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Green Day",
    "title": "Macy's Day Parade",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "Today's the Macy's Day Parade\nThe night of the livin' dead is on its way\nWith a credit report for duty call\nIt's a lifetime guarantee\nStuffed in a coffin, ten percent more free\nRed light special at the mausoleum"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Can give me somethin' that I need\nWith satisfaction guaranteed to you\nWhat's the consolation prize?\nEconomy sized dreams of hope"
      },
      {
        "label": "Verse 2",
        "index": 2,
        "text": "When I was a kid, I thought\nI wanted all the things that I haven't got\nOh-oh, but I learned the hardest way\nThen I realized what it took\nTo tell the difference between thieves and crooks\nA lesson learned to me and you"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Can give me somethin' that I need\nWith satisfaction guaranteed\n'Cause I'm thinkin' 'bout a brand new hope\nThe one I've never known\n'Cause now I know it's all that I wanted"
      },
      {
        "label": "Interlude",
        "index": 4,
        "text": ""
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "What's the consolation prize?\nEconomy sized dreams of hope\nCan give me somethin' that I need\nWith satisfaction guaranteed"
      },
      {
        "label": "",
        "index": 6,
        "text": "'Cause I'm thinkin' 'bout a brand new hope\nThe one I've never known, and where it goes\nAnd I'm thinkin' 'bout the only road\nThe one I've never known, and where it goes\nAnd I'm thinkin' 'bout a brand new hope\nThe one I've never known\n'Cause now I know it's all that I wanted"
      }
    ],
    "id": "green-day-macys-day-parade",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "1997",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "1997\nNovembro, ainda me lembro\nEra fim de ano, eu não tinha nada \ne você um novo emprego\nFoi quando tudo aconteceu"
      },
      {
        "label": "Verse",
        "index": 1,
        "text": "A vida era difícil, mas juntos tudo estava bem\nAlgumas brigas claro, mas isso é tão normal \nQuando se quer alguém\nComo eu quis você."
      },
      {
        "label": "Verse",
        "index": 2,
        "text": "Eu quis matar todos seus amigos\nFalsos e fingidos\nQue sorriam ao me ver"
      },
      {
        "label": "Verse",
        "index": 3,
        "text": "E encontrava companhia num copo de bebida, \num cigarro ou outra droga qualquer\nJá que eu não tinha mais você."
      },
      {
        "label": "Verse",
        "index": 4,
        "text": "Reaprender o caminho pra casa \nNão foi algo tão simples\nNos primeiros dias eu me perdia \nnos meus passos sem você\nEu mal sabia o que fazer"
      },
      {
        "label": "Verse",
        "index": 5,
        "text": "De vez em quando a gente \nSe encontrava nas escadas\nEu tentava dizer algo, \nVocê sempre dava risada, \nTudo vai acabar bem"
      },
      {
        "label": "Verse",
        "index": 6,
        "text": "Quase dez anos depois\nEu consigo entender\nQue eu tinha que continuar\nFosse com ou sem você"
      },
      {
        "label": "Verse",
        "index": 7,
        "text": "Nem sei como cheguei aqui\nMas saiba que eu estou feliz"
      },
      {
        "label": "Verse",
        "index": 8,
        "text": "A sua falta quase me matou\nHoje eu tenho tudo o que eu sempre quis."
      },
      {
        "label": "Verse",
        "index": 9,
        "text": "1997\nAinda me lembro de tudo \nO que eu quero esquecer!"
      }
    ],
    "id": "hateen-1997",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "Aonde Quer Que Você Vá",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Faça o que quiser\nEu não vou mais deixar você\nVoltar pra minha vida mais uma vez\nFaça aquilo que você quiser"
      },
      {
        "label": "",
        "index": 1,
        "text": "Tudo o que começa vai ter fim\nMesmo que eu não possa acreditar\nHoje é saudade que não quer partir\nMas amanhã, saudade que não quer voltar"
      },
      {
        "label": "",
        "index": 2,
        "text": "Só posso te dizer que eu\nConheço bem como é perder\nAcostumado em revirar\nAs coisas do passado"
      },
      {
        "label": "",
        "index": 3,
        "text": "Sem encontrar explicação\nVivendo cada dia às vezes não\nMas tanto faz\nTudo que eu quero é acreditar"
      },
      {
        "label": "",
        "index": 4,
        "text": "Que hoje eu vivo sem você em mim\nMas amanhã eu sei que vai\nTer fim, não vai voltar, não\nMas vou continuar\nSeguindo outra direção"
      },
      {
        "label": "",
        "index": 5,
        "text": "E onde quer que você vá\nEu quero que se lembre bem\nDe quem eu sou\nE do que eu fiz\nSó por você"
      },
      {
        "label": "",
        "index": 6,
        "text": "Agora estou um pouco melhor\nConsigo ver aonde errei\nJá sou capaz de te evitar\nJá sou capaz de te vencer"
      },
      {
        "label": "",
        "index": 7,
        "text": "Tudo o que começa vai ter fim\nMesmo que eu não possa acreditar\nHoje é saudade que não quer partir\nMas amanhã, saudade que não quer voltar"
      },
      {
        "label": "",
        "index": 8,
        "text": "E hoje eu vivo sem você em mim\nMas amanhã eu sei que vai\nTer fim, não vai voltar, não\nMas vou continuar\nSeguindo outra direção"
      },
      {
        "label": "",
        "index": 9,
        "text": "E onde quer que você vá\nEu quero que se lembre bem\nDe quem eu sou\nE do que eu fiz\nSó por você"
      },
      {
        "label": "",
        "index": 10,
        "text": "Eu sei que nem tudo teve fim\nSempre fica algo pra lembrar\nUm prêmio de consolação\nUma coleção de erros\nUm troféu pra quem perdeu"
      },
      {
        "label": "",
        "index": 11,
        "text": "E onde quer que você vá\nEu quero que se lembre bem\nE onde quer que você vá\nEu quero que se lembre bem\nE onde quer que você vá\nEu quero que se lembre bem\nAonde quer que você vá"
      }
    ],
    "id": "hateen-aonde-quer-que-voce-va",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "Coração De Plástico",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Eu não consigo acreditar\nDo que você é capaz\nMe diz confie em mim\nEu não vou mais te enganar\nEu nunca quis fazer você carregar\nTodo esse ódio dentro do seu coração"
      },
      {
        "label": "",
        "index": 1,
        "text": "Então eu sempre confiei\nNas palavras que você usou\nMe diz que é seu jeito e não consegue mudar\nQue nunca fez por mal e nem pra me magoar\nMas mesmo assim entende e sabe que errou"
      },
      {
        "label": "",
        "index": 2,
        "text": "Mentiras nunca podem apagar\nNem mudam a gente de lugar\nO derrotado e o vencedor\nQuem já partiu e quem ficou\nE que não cansa de esperar você\nEsperar você"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Dizer que nunca mais é muito tempo\nComo consegue esquecer assim\nMuda o tom e vira a página\nComo se fosse fácil\nSe desligar de tudo como fazem os robôs\nDesconectar do mundo\nCom seu coração de plástico"
      },
      {
        "label": "",
        "index": 4,
        "text": "Não estamos ficando mais jovens\nNa verdade é um caminho sem retorno\nQuando se passa dos trinta\nNinguém quer ficar só mesmo que diga o contrário\nSó para impressionar seus amigos na mesa dum bar"
      },
      {
        "label": "",
        "index": 5,
        "text": "E eu que sempre acreditei\nNas palavras que você usou\nMe diz que é seu jeito e ainda pode mudar\nQue nunca fez por mal nem pra me magoar\nMas mesmo assim entende e sabe que errou"
      },
      {
        "label": "",
        "index": 6,
        "text": "Desculpas nunca podem apagar\nNem trocam a gente de lugar\nO derrotado e o vencedor\nQuem já partiu de quem ficou\nDe quem ficou"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "Dizer que nunca mais é muito tempo\nComo consegue esquecer assim\nMuda o tom e vira a página\nComo se fosse fácil\nSe desligar de tudo como fazem os robôs\nDesconectar do mundo\nCom seu coração de plástico"
      },
      {
        "label": "",
        "index": 8,
        "text": "Estou vivendo o que eu não quero viver\nTodo dia eu procuro você\nCom seu coração de plástico\nEstou vivendo o que eu não quero viver"
      }
    ],
    "id": "hateen-coracao-de-plastico",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "Depois Que Todos Vão Embora",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "Deixa eu te contar\nA verdade sobre mim\nEu não sou quem você pensa mais"
      },
      {
        "label": "Verse",
        "index": 1,
        "text": "Um dia acordei assim\nSem nem saber pra onde ir\nUm dia acordei assim\nTodo quebrado e sem razão"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Você não sabe como é ficar aqui\nDepois que todos vão embora\nDepois que tudo perde o valor"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Você não pode entender\nO que você nunca sentiu\nMas hoje tudo vai mudar\nMais pra você do que pra mim"
      },
      {
        "label": "Verse",
        "index": 4,
        "text": "Deixa eu te contar\nTalvez exista uma maneira\nQue consiga explicar\nO que acontece na minha cabeça"
      },
      {
        "label": "Verse",
        "index": 5,
        "text": "Foi tanto que eu vivi\nFoi tanto que eu tentei não esquecer\nAgora já não importa mais"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "Você não sabe como é ficar aqui\nDepois que todos vão embora\nDepois que tudo perde o valor"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "Você não pode entender\nO que você nunca sentiu\nMas hoje tudo vai mudar\nMais pra você"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "Tudo muda de lugar\nMais pra você do que pra mim"
      },
      {
        "label": "Chorus",
        "index": 9,
        "text": "Você não sabe como é ficar aqui\nDepois que todos vão embora\nDepois que tudo perde o valor"
      },
      {
        "label": "Chorus",
        "index": 10,
        "text": "Você não pode entender\nO que você nunca sentiu\nMas hoje tudo vai mudar\nMais pra você"
      },
      {
        "label": "Chorus",
        "index": 11,
        "text": "Hoje tudo vai mudar\nMais pra você\nTudo muda de lugar\nMais pra você do que pra mim"
      }
    ],
    "id": "hateen-depois-que-todos-vao-embora",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "Despedida (Com Dó Menor)",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Eu sigo em frente, firme e forte\nDe repente com um pouco de sorte\nEu consiga entender melhor"
      },
      {
        "label": "",
        "index": 1,
        "text": "Quando a sua falta vem me visitar\nE lembro do que você sempre quis pra mim\nQue eu pudesse só viver feliz enfim"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Saudade é o amor de quem fica aqui\nSentindo a sua falta\nE eu só queria voltar pra outro lugar\nPra reaprender a lidar com a vida\nNão existe mistério\nOohh não, não leve tão a sério!"
      },
      {
        "label": "",
        "index": 3,
        "text": "Eu sigo em frente sem meu norte\nBuscando algo que me importe\nJá que é tudo sem graça ao meu redor\nQuando a sua falta vem me visitar\nE lembro do que você sempre quis pra mim\nQue eu pudesse só viver feliz enfim"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "Saudade é o amor de quem fica aqui\nSentindo a sua falta\nE eu só queria voltar pra outro lugar\nPra reaprender a lidar com a vida\nNão existe mistério\nOohh não, não leve tão a sério!"
      }
    ],
    "id": "hateen-despedida-em-do-menor",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "Passa O Tempo",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Sei que está em outro lugar\nNão quer dizer que eu não vou te procurar\nAtormentado e esquecido é como eu me sinto aqui\nQuando você foi embora e joguei tudo fora"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Quando amanhece o meu corpo todo dói\nMeu Deus, eu só queria poder ouvir sua voz\nPra me dizer que fomos mais que um momento\nMuito mais do que um mero passatempo\nMuito mais do que deixamos pra depois\nMuito mais do que o que existe entre nós dois\nMas não sobrou nada\nNão tem nada, nada"
      },
      {
        "label": "",
        "index": 2,
        "text": "Sei que está em outro lugar\nAmanheceu de novo e eu não quero acordar\nTrancado em um pesadelo que nunca chega ao fim\nEu te procuro em todo lugar, mas eu só te encontro em mim"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Quando amanhece o meu corpo todo dói\nMeu Deus, eu só queria poder ouvir sua voz\nPra me dizer que fomos mais que um momento\nMuito mais do que um mero passatempo\nMuito mais do que deixamos pra depois\nMuito mais do que o que existe entre nós dois\nMas não sobrou nada\nNão tem nada, nada"
      },
      {
        "label": "",
        "index": 4,
        "text": "Cada dia sem ter você aqui\nÉ outro dia sem sentido, sem ter pra onde ir\nO tempo não passa, eu não me sinto bem\nEstou bebendo até cair para esquecer você\nE outro dia vem, começa outra vez\nE eu me levanto mesmo sem saber porquê\nPassa o tempo que for\nO fim é sempre igual\nPassa o tempo que for\nO fim é sempre igual"
      }
    ],
    "id": "hateen-passa-o-tempo",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "Perfeitamente Imperfeito",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "Coragem aos que tem valor\nQue nunca pensam em se entregar\nCoragem pra poder continuar\nE não existe nada que você\nNão seja forte o bastante pra vencer\nPorque você sabe que nunca está sozinho"
      },
      {
        "label": "Verse",
        "index": 1,
        "text": "E eu também já vivi assim\nPerdido e procurando o fim\nSó pra fazer toda essa dor passar\nMas na vida tudo que aprendi\nÉ que a gente chora, mas também sorri\nE tudo sempre vai acabar bem\nBasta acreditar"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Que todos os seus dias vão melhorar com o tempo\nQue todos seus temores, erros, faltas e defeitos\nFazem de você perfeitamente imperfeito\nÉ assim que deve ser\nE é assim que eu vou seguir em frente"
      },
      {
        "label": "Verse",
        "index": 3,
        "text": "Se lembra quando você não conseguia entender\nE o seu ódio era o seu melhor amigo\nMas o tempo que você passou\nBrigando com o mundo inteiro ao seu redor\nNunca te fez sentir um pouco melhor"
      },
      {
        "label": "Verse",
        "index": 4,
        "text": "São coisas que a gente aprende com o tempo\nA dar valor a todos os momentos\nE da tristeza a gente faz sorrir\nAgora entendo e já parece tão certo\nEstou feliz e já me sinto completo\nPorque tenho a certeza de que não acabo aqui\nBasta acreditar"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Que todos os seus dias vão melhorar com o tempo\nQue todos seus temores, erros, faltas e defeitos\nFazem de você perfeitamente imperfeito\nÉ assim que deve ser\nE é assim que eu vou seguir"
      },
      {
        "label": "Verse",
        "index": 6,
        "text": "Wooh wooh wooh"
      },
      {
        "label": "Bridge",
        "index": 7,
        "text": "Fazem de você perfeitamente imperfeito\nFazem de você perfeitamente imperfeito\nFazem de você perfeitamente imperfeito\nWooh"
      },
      {
        "label": "Outro",
        "index": 8,
        "text": "Se lembra quando você não conseguia entender"
      }
    ],
    "id": "hateen-perfeitamente-imperfeito",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Hateen",
    "title": "Você Não Pode Desistir",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "É só mais um dia que acaba com você\nE você volta do trabalho \nse sentindo tão cansado"
      },
      {
        "label": "",
        "index": 1,
        "text": "Com tantas contas pra pagar, sem perceber\nNo fim do mês não sobra um centavo\nMas você não pode desistir\nVocê não pode desistir"
      },
      {
        "label": "",
        "index": 2,
        "text": "E outro dia chega e você se levanta\nToma um café e lê o jornal, \nsem entender uma palavra"
      },
      {
        "label": "",
        "index": 3,
        "text": "E sai de casa atordoado dez minutos atrasados\nMais de uma hora preso no congestionamento\nE deve ser suficiente, pra você querer fugir\nPra você querer deixar, sua vida para trás"
      },
      {
        "label": "",
        "index": 4,
        "text": "Então se lembra sem querer\nDá sua razão para viver\nUma esposa amada e um filho ainda pequeno\nSempre esperando por você"
      },
      {
        "label": "",
        "index": 5,
        "text": "Por eles você segue em frente, mais um dia\nE tem certeza que é a pessoa\nMais feliz que já existiu"
      },
      {
        "label": "",
        "index": 6,
        "text": "Não importa quantos dias chatos\nQue te matam devagar\nVocê vai ter que encarar, pra se sentir merecedor"
      },
      {
        "label": "",
        "index": 7,
        "text": "E dar valor, a tudo que você tem\nVocê não pode desistir"
      },
      {
        "label": "",
        "index": 8,
        "text": "Você nunca vai desistir \nE nunca mais voltar"
      },
      {
        "label": "",
        "index": 9,
        "text": "Você não pode desistir\nE nunca mais voltar"
      },
      {
        "label": "",
        "index": 10,
        "text": "Você não pode desistir\nVocê nunca vai desistir"
      }
    ],
    "id": "hateen-voce-nao-pode-desistir",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Jack Johnson",
    "title": "Better Together",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "There's no combination of words I could put on the back of a postcard\nNo song that I could sing but I can try for your heart\nOur dreams and they are made out of real things\nLike a shoebox of photographs with sepia-toned loving\nLove is the answer, at least for most of the questions in my heart\nLike why are we here and where do we go, and how come it's so hard?\nIt's not always easy and sometimes life can be deceiving\nI'll tell you one thing, it's always better when we're together"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Mmm, it's always better when we're together\nYeah, we'll look at them stars and we're together\nWell, it's always better when we're together\nYeah, it's always better when we're together"
      },
      {
        "label": "Verse 2",
        "index": 2,
        "text": "And all of these moments just might find their way into my dreams tonight\nBut I know that they'll be gone when the morning light sings\nOr brings new things, for tomorrow night, you see\nThat they'll be gone too, too many things I have to do\nBut if all of these dreams might find their way into my day to day scene\nI'd be under the impression I was somewhere in between\nWith only two, just me and you, not so many things we got to do\nOr places we got to be, we'll sit beneath the mango tree now"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Yeah, it's always better when we're together\nMmm, we're somewhere in between together\nWell, it's always better when we're together\nYeah, it's always better when we're together"
      },
      {
        "label": "Post-Chorus",
        "index": 4,
        "text": "Mm-mm-mm-mm, mm-mm-mm\nMm-mm-mm-mm, mm-mm-mm"
      },
      {
        "label": "Outro",
        "index": 5,
        "text": "I believe in memories\nThey look so, so pretty when I sleep\nAnd I when, and when I wake up\nYou look so pretty, sleeping next to me\nBut there is not enough time\nAnd there is no, no song I could sing\nAnd there is no combination of words I could say\nBut I will still tell you one thing\nWe're better together"
      }
    ],
    "id": "jack-johnson-better-together",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Jack Johnson",
    "title": "Sitting, Waiting, Wishing",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "Well I was sitting, waiting, wishing\nYou believed in superstitions\nThen maybe you'd see the signs\nBut Lord knows that this world is cruel\nAnd I ain't the Lord, no I'm just a fool\nLearning loving somebody don't make them love you"
      },
      {
        "label": "Chorus 1",
        "index": 1,
        "text": "Must I always be waiting, waiting on you?\nMust I always be playing, playing your fool?"
      },
      {
        "label": "Verse 2",
        "index": 2,
        "text": "I sang your songs, I danced your dance\nI gave your friends all a chance\nBut putting up with them wasn't worth never having you\nAh, maybe you've been through this before\nBut it's my first time so please ignore\nThe next few lines 'cause they're directed at you"
      },
      {
        "label": "Chorus 2",
        "index": 3,
        "text": "I can't always be waiting, waiting on you\nI can't always be playing, playing your fool"
      },
      {
        "label": "Bridge 1",
        "index": 4,
        "text": "I keep playing your part\nBut it's not my scene\nWant this plot to twist\nI've had enough mystery\nKeep building it up\nBut then you're shooting me down\nBut I'm already down"
      },
      {
        "label": "Bridge 2",
        "index": 5,
        "text": "Just wait a minute\nJust sitting, waiting\nJust wait a minute\nJust sitting, waiting"
      },
      {
        "label": "Verse 3",
        "index": 6,
        "text": "Well if I was in your position\nI'd put down all my ammunition\nI'd wonder why'd it taken me so long\nBut lord knows that I'm not you\nAnd if I was I wouldn't be so cruel\n'Cause waiting on love ain't so easy to do"
      },
      {
        "label": "Chorus 1",
        "index": 7,
        "text": "Must I always be waiting, waiting on you?\nMust I always be playing, playing your fool?"
      },
      {
        "label": "Chorus 2",
        "index": 8,
        "text": "No I can't always be waiting, waiting on you\nI can't always be playing, playing your fool"
      },
      {
        "label": "Outro",
        "index": 9,
        "text": "Fool, hm hm"
      }
    ],
    "id": "jack-johnson-sitting-waiting-wishing",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Jack Johnson",
    "title": "Upside Down",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "Who's to say\nWhat's impossible? Well, they forgot\nThis world keeps spinning, and with each new day\nI can feel a change in everything\nAnd as the surface breaks, reflections fade\nBut in some ways, they remain the same\nAnd as my mind begins to spread its wings\nThere's no stopping curiosity"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "I wanna turn the whole thing upside down\nI'll find the things they say just can't be found\nI'll share this love I find with everyone\nWe'll sing and dance to Mother Nature's songs\nI don't want this feeling to go away"
      },
      {
        "label": "Verse 2",
        "index": 2,
        "text": "Who's to say\nI can't do everything? Well, I can try\nAnd as I roll along I begin to find\nThings aren't always just what they seem"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "I wanna turn the whole thing upside down\nI'll find the things they say just can't be found\nI'll share this love I find with everyone\nWe'll sing and dance to Mother Nature's songs\nThis world keeps spinning"
      },
      {
        "label": "Bridge",
        "index": 4,
        "text": "And there's no time to waste\nWell, it all keeps spinning, spinning 'round and 'round\nAnd upside down\nWho's to say what's impossible and can't be found?\nI don't want this feeling to go away"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "I wanna turn the whole thing upside down\nI'll find the things they say just can't be found\nI'll share this love I find with everyone\nWe'll sing and dance to Mother Nature's songs\nI don't want this feeling to go away"
      },
      {
        "label": "Outro",
        "index": 6,
        "text": "Please don't go away\nPlease don't go away\nPlease don't go away\nIs this how it's supposed to be?\nIs this how it's supposed to be?"
      }
    ],
    "id": "jack-johnson-upside-down",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Linkin Park",
    "title": "Leave Out The Rest",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "I dreamed I was missing\nYou were so scared\nBut no one would listen\nCause no one else cared"
      },
      {
        "label": "Verse",
        "index": 1,
        "text": "After my dreaming\nI woke with this fear\nWhat am I leaving\nWhen I'm done here?"
      },
      {
        "label": "Pre Chorus",
        "index": 2,
        "text": "So, if you're asking me, I want you to know"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "When my time comes\nForget the wrong that I've done\nHelp me leave behind some reasons to be missed"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "And don't resent me\nAnd when you're feeling empty\nKeep me in your memory\nLeave out all the rest\nLeave out all the rest"
      },
      {
        "label": "Verse",
        "index": 5,
        "text": "Don't be afraid\nI've taken my beating\nI've shared what I've made"
      },
      {
        "label": "Verse",
        "index": 6,
        "text": "I'm strong on the surface\nNot all the way through\nI've never been perfect\nBut neither have you"
      },
      {
        "label": "Pre Chorus",
        "index": 7,
        "text": "So, if you're asking me, I want you to know"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "When my time comes\nForget the wrong that I've done\nHelp me leave behind some reasons to be missed"
      },
      {
        "label": "Chorus",
        "index": 9,
        "text": "And don't resent me\nAnd when you're feeling empty\nKeep me in your memory\nLeave out all the rest\nLeave out all the rest"
      },
      {
        "label": "Bridge",
        "index": 10,
        "text": "Forgetting\nAll the hurt inside you've learned to hide so well\nPretending\nSomeone else can come and save me from myself\nI can't be who you are"
      },
      {
        "label": "Chorus",
        "index": 11,
        "text": "When my time comes\nForget the wrong that I've done\nHelp me leave behind some reasons to be missed"
      },
      {
        "label": "Chorus",
        "index": 12,
        "text": "And don't resent me\nAnd when you're feeling empty\nKeep me in your memory\nLeave out all the rest\nLeave out all the rest"
      },
      {
        "label": "Bridge",
        "index": 13,
        "text": "Forgetting\nAll the hurt inside you've learned to hide so well\nPretending\nSomeone else can come and save me from myself\nI can't be who you are\nI can't be who you are"
      }
    ],
    "id": "linkin-park-leave-out-the-rest",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Linkin Park",
    "title": "Nobody Can Save Me",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "I'm dancing with my demon\nI'm hanging off the edge\nStorm clouds gather beneath me\nWaves break above my head"
      },
      {
        "label": "Pre Chorus",
        "index": 1,
        "text": "Head first hallucination\nI wanna fall wide awake now"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "You tell me it's alright\nTell me I'm forgiven tonight\nBut nobody can save me now"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "I'm holding up a light\nI'm chasing out the darkness inside\n'Cause nobody can save me"
      },
      {
        "label": "Verse",
        "index": 4,
        "text": "Stared into this illusion\nFor answers yet to come\nI chose a false solution\nNobody proved me wrong, no"
      },
      {
        "label": "Pre Chorus",
        "index": 5,
        "text": "Head first hallucination\nI wanna fall wide awake\nWatch the ground giving way now"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "You tell me it's alright\nTell me I'm forgiven tonight\nBut nobody can save me now"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "I'm holding up a light\nI'm chasing out the darkness inside\n'Cause nobody can save me"
      },
      {
        "label": "Bridge",
        "index": 8,
        "text": "Been searching somewhere out there\nFor what's been missing right here\nI've been searching somewhere out there\nFor what's been missing right here"
      },
      {
        "label": "Pre Chorus",
        "index": 9,
        "text": "I wanna fall wide awake now"
      },
      {
        "label": "Chorus",
        "index": 10,
        "text": "Tell me it's alright\nTell me I'm forgiven tonight\nAnd only I can save me now"
      },
      {
        "label": "Chorus",
        "index": 11,
        "text": "I'm holding up a light\nChasing out the darkness inside\nAnd I don't wanna let you down\nBut only I can save me"
      },
      {
        "label": "Outro",
        "index": 12,
        "text": "And I don't want to let you\nBeen searching somewhere out there\nFor what's been missing right here"
      }
    ],
    "id": "linkin-park-nobody-can-save-me",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Linkin Park",
    "title": "One More Light",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "Should've stayed. Were there signs I ignored?\nCan I help you not to hurt anymore?\nWe saw brilliance when the world was asleep\nThere are things that we can have but can't keep\nIf they say"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Who cares if one more light goes out\nIn the sky of a million stars?\nIt flickers, flickers\nWho cares when someone's time runs out\nIf a moment is all we are?\nOr quicker, quicker\nWho cares if one more light goes out?\nWell, I do"
      },
      {
        "label": "Verse",
        "index": 2,
        "text": "The reminders pull the floor from your feet\nIn the kitchen one more chair than you need, oh\nAnd you're angry, and you should be, it's not fair\nJust 'cause you can't see it, doesn't mean it isn't there\nIf they say"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Who cares if one more light goes out\nIn the sky of a million stars?\nIt flickers, flickers\nWho cares when someone's time runs out\nIf a moment is all we are?\nOr quicker, quicker\nWho cares if one more light goes out?\nWell, I do"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "Who cares if one more light goes out\nIn the sky of a million stars?\nIt flickers, flickers\nWho cares when someone's time runs out\nIf a moment is all we are?\nOr quicker, quicker\nWho cares if one more light goes out?\nWell, I do\nWell, I do"
      }
    ],
    "id": "linkin-park-one-more-light",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Linkin Park",
    "title": "Shadow Of The Day",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "I close both locks below the window\nI close both blinds and turn away\nSometimes solutions aren't so simple\nSometimes goodbye's the only way, oh"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "And the sun will set for you\nThe sun will set for you\nAnd the shadow of the day\nWill embrace the world in grey\nAnd the sun will set for you"
      },
      {
        "label": "Verse",
        "index": 2,
        "text": "In cards and flowers on your window\nYour friends all plead for you to stay\nSometimes beginnings aren't so simple\nSometimes goodbye's the only way, oh"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "And the sun will set for you\nThe sun will set for you\nAnd the shadow of the day\nWill embrace the world in grey\nAnd the sun will set for you"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "And the shadow of the day\nWill embrace the world in grey\nAnd the sun will set for you"
      }
    ],
    "id": "linkin-park-shadow-of-the-day",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Linkin Park",
    "title": "Sharp Edges",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "Mama always told me don't you run\nDon't you run with scissors, son\nYou're gonna hurt someone"
      },
      {
        "label": "Verse",
        "index": 1,
        "text": "Mama told me look before you leap\nAlways think before you speak, and watch the friends you keep"
      },
      {
        "label": "Pre-Chorus",
        "index": 2,
        "text": "Stay along the beaten path, never listened when she said"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Sharp edges have consequences\nI guess that I had to find out for myself\nSharp edges have consequences\nNow every scar is a story I can tell"
      },
      {
        "label": "Verse",
        "index": 4,
        "text": "Should've played safer from the start\nLoved you like a house of cards\nLet it fall apart"
      },
      {
        "label": "Verse",
        "index": 5,
        "text": "But all the things I couldn't understand\nNever could've planned\nThey made me who I am"
      },
      {
        "label": "Pre-Chorus",
        "index": 6,
        "text": "Put your nose on paperbacks\nInstead of smoking cigarettes\nThese years you're never getting back\nStay along the beaten path, never listened when she said"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "Sharp edges have consequences\nI guess that I had to find out for myself\nSharp edges have consequences\nNow every scar is a story I can tell"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "We all fall down\nWe live somehow\nWe learn what doesn't kill us makes us stronger\nWe all fall down\nWe live somehow\nWe learn what doesn't kill us makes us stronger\nOoh"
      },
      {
        "label": "Pre-Chorus",
        "index": 9,
        "text": "Stay along the beaten path, never listened when she said"
      },
      {
        "label": "Chorus",
        "index": 10,
        "text": "Sharp edges have consequences\nI guess that I had to find out for myself\nSharp edges have consequences\nNow every scar is a story I can tell"
      },
      {
        "label": "Chorus",
        "index": 11,
        "text": "We all fall down\nWe live somehow\nWe learn what doesn't kill us makes us stronger\nWe all fall down\nWe live somehow\nWe learn what doesn't kill us makes us stronger, ooh"
      }
    ],
    "id": "linkin-park-sharp-edges",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Millencolin",
    "title": "The Ballad",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "The last selection in the ballgame.\nDoes never get a pass.\n\"Not appreciated's\" just his first name.\nHe's the scapegoat of the class.\nThere are no friends to cheer him up and\nno girls, no sweet romance.\nIt's impossible to expand,\nwhen you never get a second chance."
      },
      {
        "label": "",
        "index": 1,
        "text": "Do you know, who's that guy, who's all alone?\nDo you care enough to see? He's in pain and misery."
      },
      {
        "label": "",
        "index": 2,
        "text": "He's not going to the school-prom.\nHe said he had the flu.\nJumped-up excuses as he told mom.\n-I'm safer here with you.\nShe told her son. \n-Someday, they'll all be\nsorry for mistreating you.\nDon't be afraid my son and trust me.\n-You'll be someone they will look up to."
      },
      {
        "label": "",
        "index": 3,
        "text": "Do you know, who's that guy, who's all alone?\nDo you care enough to see?\nHe's in pain and misery... Do you care enough to see?"
      },
      {
        "label": "",
        "index": 4,
        "text": "Do you know, who's that guy, who's all alone?\nDo you care enough to see? He's in pain and misery."
      }
    ],
    "id": "millencolin-the-ballad",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Grey Skies Turn Blue",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "You say you want me to say \"I'm sorry.\"\nBut I don't have to \nAnd I don't want to say it\n'Cause I'm not sorry"
      },
      {
        "label": "",
        "index": 1,
        "text": "I play the game and \nI can't really say that I like it\nCan't really say that I hate it\nCan't really say that I paid attention"
      },
      {
        "label": "",
        "index": 2,
        "text": "When grey skies turn blue\nI'll come back for you\nMy mind can't change my heart \nThere's nothing I can do"
      },
      {
        "label": "",
        "index": 3,
        "text": "When grey skies turn blue\nWhen dreams do come true\nWill you be the way that I remember you"
      },
      {
        "label": "",
        "index": 4,
        "text": "You say that life with me is driving you crazy\nBut I don't see it and I don't want see\nSo I, I must be crazy"
      },
      {
        "label": "",
        "index": 5,
        "text": "I didn't mean a thing \nWhen I said \"Nothing is fleeting.\"\nOr worth repeating (worth repeating) \nOr worth repeating (worth repeating)"
      },
      {
        "label": "",
        "index": 6,
        "text": "When grey skies turn blue\nI'll come back for you\nMy mind can't change my heart\nThere's nothing I can do"
      },
      {
        "label": "",
        "index": 7,
        "text": "When grey skies turn blue\nWhen dreams do come true\nWill you be the way that I remember you"
      },
      {
        "label": "",
        "index": 8,
        "text": "The cold wind on my face\nIs the only thing I felt\nIn such a long time\nIn such a long time\nIt's been a long time\nYeah, yeah"
      },
      {
        "label": "",
        "index": 9,
        "text": "When grey skies turn blue\nI'll come back for you\nMy mind can't change my heart \nThere's nothing I can do"
      },
      {
        "label": "",
        "index": 10,
        "text": "When grey skies turn blue\nWhen dreams do come true\nWill you be the way that I remember you"
      }
    ],
    "id": "mxpx-grey-sky-turn-blue",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Heard That Sound",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Without a window to see through,\nI did my time, in here without you,\nSlept on the floor down in the LEC,\nMeet me back home and do the mocking."
      },
      {
        "label": "",
        "index": 1,
        "text": "It seems like misery loves misery,\nMy favourite songs they keep me company,\nSo many memories come down to this,\nI maybe be lost but I'm not hopeless."
      },
      {
        "label": "",
        "index": 2,
        "text": "I heard that sound a mile away\nI heard that sound a mile away\nAll those things i wish that i could say\nI heard that sound a mile away\nA mile away"
      },
      {
        "label": "",
        "index": 3,
        "text": "Came in like static off the tv,\nThis kinda thing over goes easy,\nI meant to tell you but you were gone,\nI tried to get it right, i got it all wrong"
      },
      {
        "label": "",
        "index": 4,
        "text": "I heard that sound a mile away\nI heard that sound a mile away\nAll those things i wish that i could say\nI heard that sound a mile away\nA mile away"
      },
      {
        "label": "",
        "index": 5,
        "text": "Sat outside the show,\nWith nowhere else, nowhere else to go\nDon't say \"can't see\"\nIt sure does sound, get to me"
      },
      {
        "label": "",
        "index": 6,
        "text": "(Whoa oaaa) Na Na Na Na Na (Whoa oaaa)\nDon't save yourself....get to me\n(Whoa oa) Na Na Na Na Na\nGet to you....get to me"
      },
      {
        "label": "",
        "index": 7,
        "text": "I heard that sound a mile away\nI heard that sound a mile away\nAll those things i wish that i could say\nI heard that sound a mile away\nA mile away"
      }
    ],
    "id": "mxpx-heard-that-sound",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "I'm OK, You're OK",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "What's going on tonight?\nIs everything alright?\nI hope that nothing's wrong.\nI haven't seen you in so long."
      },
      {
        "label": "",
        "index": 1,
        "text": "I'm away, you're here to stay\nand I'm away and you're ok\nYou're here to stay and I'm away,\nyou're here to stay and I'm ok"
      },
      {
        "label": "",
        "index": 2,
        "text": "What exactly do you do,\nwhen I'm not with you?\nWhat exactly do you say,\nall the time that I'm away?"
      },
      {
        "label": "",
        "index": 3,
        "text": "You tell me nothing's wrong\nSeems like I've been gone oh so long \nNothing seems to have changed \nYet the familiar things seem all so strange"
      }
    ],
    "id": "mxpx-im-ok-youre-ok",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Invitation To Understanding",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "Come on understanding visit me for once today\nI'll be grateful you don't even have to stay\nIt must be hard for you to get over to my side of town\nI know there's many people like me to be found"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "If you come knockin' at my door\nAnd I am not around\nFoolishness came by and we're downtown\nPlease don't leave\nPlease come on in and make yourself at home\nI know you're probably used to being alone"
      },
      {
        "label": "Verse",
        "index": 2,
        "text": "Everyone keeps telling me you're something that you're not\nBut I know if I met you I'd like you a lot\nWhat exactly does it take to bring you to my door\nBy the time that you arrive I won't live here no more"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "If you come knockin' at my door\nAnd I am not around\nFoolishness came by and we're downtown\nPlease don't leave\nPlease come on in and make yourself at home\nI know you're probably used to being alone"
      },
      {
        "label": "Bridge",
        "index": 4,
        "text": "What can I do? There's no one here but me\nAnd nothin' on tv\nWhere could you be? I really wouldn't know\nWhere else you might go"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "If you come knockin' at my door\nAnd I am not around\nFoolishness came by and we're downtown\nPlease don't leave\nPlease come on in and make yourself at home\nI know you're probably used to being alone"
      },
      {
        "label": "",
        "index": 6,
        "text": "I know you used to be alone"
      }
    ],
    "id": "mxpx-invitation-to-understanding",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Quit Your Life",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I’ve been everywhere around the world \nand finally here tonight\nYou seem to be the only one \nto make me feel alright"
      },
      {
        "label": "",
        "index": 1,
        "text": "I feel good when I know you’re coming down\nI feel good when I know you’ll be around"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "So quit your life and stay with me\nWe’ll order in and watch TV\nWe’ll paint the house and wash the car\nWe’ll take a walk but not too far\nSo quit your life and stay with me"
      },
      {
        "label": "",
        "index": 3,
        "text": "New York to Los Angeles \nand all the towns between\nAfter miles and hours on the road, \nyou’re the best I’ve seen"
      },
      {
        "label": "",
        "index": 4,
        "text": "I feel good, you’re the best friend that I’ve found\nI feel good when I’m coming back to your town"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "So quit your life and stay with me\nWe’ll order in and watch TV\nWe’ll paint the house and wash the car\nWe’ll take a walk but not too far\nSo quit your life and stay with me"
      },
      {
        "label": "",
        "index": 6,
        "text": "Stay with me"
      },
      {
        "label": "",
        "index": 7,
        "text": "I heard the road, it was calling my name\nI walked towards the light \nAnd I ran towards the flame\nI ran towards the flame"
      },
      {
        "label": "",
        "index": 8,
        "text": "After all is said and done,\nI don’t mind saying again\nI say it’s true that I’m in love \nWith the places that I’ve been"
      },
      {
        "label": "",
        "index": 9,
        "text": "I feel good when I know you’re coming down\nI feel good when I know you’ll be around"
      },
      {
        "label": "Chorus",
        "index": 10,
        "text": "So quit your life and stay with me\nWe’ll order in and watch TV\nWe’ll paint the house and wash the car\nWe’ll take a walk but not too far\nSo quit your life and stay with me"
      },
      {
        "label": "",
        "index": 11,
        "text": "So quit your life and stay with me\nStay with me"
      }
    ],
    "id": "mxpx-quit-your-life",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Set The Record Straight",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "Did we ever have an understanding?\nDid we ever have anything at all?\nNot that I recall\nYou were never really there for us but then\nWe were never really there for you\nIts true, the rest is up to you"
      },
      {
        "label": "Verse 2",
        "index": 1,
        "text": "You say it's wrong, its right\nYou say we won't, we might\nWe thought about it twice tonight\nAnd if we don't seem to broken hearted\nThat because were glad we've parted\nI think we've had enough for two"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Well I believe in second chances\nI believe the world is gonna end\nBut I would do anything to set the record straight\nIf you would believe\nIf you would believe"
      },
      {
        "label": "Verse 3",
        "index": 3,
        "text": "Will someone think about the heartbreak?\nWill someone realize there has been a mistake?\nAnd give back all you take\nNo one really cares enough to listen\nNo one cares enough to rationalize\nJust believe the lies"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "Well I believe in second chances\nI believe the world is gonna end\nBut I would do anything to set the record straight\nIf you would believe\nIf you would believe"
      },
      {
        "label": "Verse 1",
        "index": 5,
        "text": "Did we ever have an understanding?\nDid we ever have anything at all?\nNot that I recall\nYou were never really there for us but then\nWe were never really there for you\nIt's true the rest is up to you"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "Well I believe in second chances\nI believe the world is gonna end\nBut I would do anything to set the record straight\nIf you would believe"
      }
    ],
    "id": "mxpx-set-the-record-straight",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "The Silver Screen",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "The screaming was silver, this silence was gold\nThe skin felt so warm, the blood ran so cold\nI felt like a character that dies in the end\nThe credits roll quickly, the frames blur and blend"
      },
      {
        "label": "",
        "index": 1,
        "text": "The fades were in genius, the cuts were so clean\nIf I could just realize what they meant to me\nWhat have I done as I'm holding the gun\nI murdered this love now, I'm on the run"
      },
      {
        "label": "",
        "index": 2,
        "text": "On The Silver Screen\nRide off in the sunset with me\nThe curtains would close and the credits would roll\nOn the Silver Screen"
      },
      {
        "label": "",
        "index": 3,
        "text": "A fugitive running when bad times arrive\nMe and my heart wanted dead or alive\nSend out the hounds to pick up the sent\nPut up some posters to find where your love went"
      },
      {
        "label": "",
        "index": 4,
        "text": "On The Silver Screen\nRide off in the sunset with me\nThe curtains would close and the credits would roll\nOn the Silver Screen"
      },
      {
        "label": "",
        "index": 5,
        "text": "I picture a sun setting on a small town\nWhere music was playing on snow cover grounds\nThe smell of your sweet skin was too much to bear\nTo reach out and touch, to play with your hair"
      },
      {
        "label": "",
        "index": 6,
        "text": "On The Silver Screen\nRide off in the sunset with me\nThe curtains would close and the credits would roll\nOn the Silver Screen"
      },
      {
        "label": "",
        "index": 7,
        "text": "The curtains would close and the credits would roll\nOn the Silver Screen"
      }
    ],
    "id": "mxpx-silver-screen",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Tomorrow's Another Day",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I don't want to let my life fly by \nDo you ever stop, stop to wonder why?"
      },
      {
        "label": "",
        "index": 1,
        "text": "And time flies by \nWhen everything is ok \nIt just turns out that life ain't that way \nBig decisions overwhelm me and I know \nnothing's free"
      },
      {
        "label": "",
        "index": 2,
        "text": "When I don't think of, think about much \nPeople die and we don't know why \nI could use some understanding \nHuman contact, sign that contract"
      },
      {
        "label": "",
        "index": 3,
        "text": "I don't want to let my life fly by \nDid you ever stop to wonder why-ever stop to \nwonder, wonder why?"
      },
      {
        "label": "",
        "index": 4,
        "text": "And time stands still when no one understands you \nWhen you don't quite understand yourself \nBut just know this that God is faithful \nEven if you don't have faith yourself"
      },
      {
        "label": "",
        "index": 5,
        "text": "There's nothing quite like being sure of \nWhat's inside your heart \nIt's mostly simple but not so easy \nTo know just where to start"
      },
      {
        "label": "",
        "index": 6,
        "text": "Today didn't have to be this way \nTomorrow is another day \nAnother chance to make things right \nA chance to make sense of last night"
      },
      {
        "label": "",
        "index": 7,
        "text": "A chance to fully live your life \nI don't want to, I don't want to, you don't want to"
      }
    ],
    "id": "mxpx-tomorrows-another-day",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Worries",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I know you've got a lotta worries\nAnd you know I've got a lotta worries\nWe can worry them away\nAnd live to see another day"
      },
      {
        "label": "",
        "index": 1,
        "text": "When you feel your stomach\nTurnin' in a hurry\nMouth dry, hands shakin', vision blurry\nFrom the bad economy\nMore than when you're puttin' up with me"
      },
      {
        "label": "",
        "index": 2,
        "text": "I'm not worried, I'll be fine\nJust want you to know you're on my mind\nBut I'm not worried, just worried sick\nA little dose of you might do the trick\nYou're the cure for worried sick"
      },
      {
        "label": "",
        "index": 3,
        "text": "This might not be what you expected\nThis might be the one time\nThat I stand corrected\nAnd it's hard to be right (right)\nIt's easier to fight (fight)"
      },
      {
        "label": "",
        "index": 4,
        "text": "I'm not worried, I'll be fine\nJust want you to know you're on my mind\nBut I'm not worried, just worried sick\nA little dose of you might do the trick\nYou're the cure for worried sick"
      },
      {
        "label": "",
        "index": 5,
        "text": "I'm not worried, I'll be fine\nJust want you to know you're on my mind\nYeah!\nBut I'm not worried, just worried sick\nA little dose of you might do the trick\nYou're the cure for worried sick"
      }
    ],
    "id": "mxpx-worries",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "MxPx",
    "title": "Wrecking Hotel Rooms",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I wrecked your hotel room\nI crashed up the car\nSlept in the gutter\nI'm outside your door"
      },
      {
        "label": "",
        "index": 1,
        "text": "Saw you through the key hole\nDown on your knees\nThere's really no reason to call the police"
      },
      {
        "label": "",
        "index": 2,
        "text": "Do you hold the phone when you're alone\nImagine that it rings then hold it to your ear\nAnd wish that I was there to sing you off to sleep\nBe with you in your dreams for the days I will be gone\nI'll be there in your dreams and in this song"
      },
      {
        "label": "",
        "index": 3,
        "text": "I know you don't know me\n'Cause we've never met\nI'm not really crazy\nSo don't get upset"
      },
      {
        "label": "",
        "index": 4,
        "text": "I've got all these problems\nNone with solutions\nBeen in and out of some nice institutions"
      },
      {
        "label": "",
        "index": 5,
        "text": "Do you hold the phone when you're alone\nImagine that it rings then hold it to your ear\nAnd wish that I was there to sing you off to sleep\nBe with you in your dreams for the days I will be gone\nI'll be there in your dreams and in this song\nI'll be there in your dreams and in this song"
      },
      {
        "label": "",
        "index": 6,
        "text": "Do you hold the phone when you're alone\nImagine that it rings then hold it to your ear\nAnd wish that I was there to sing you off to sleep\nBe with you in your dreams for the days I will be gone\nI'll be there in your dreams and in this song\nI'll be there in your dreams and in this song\nI'll be there in your dreams and in this song\nI'll be there in your dreams and in this song"
      }
    ],
    "id": "mxpx-wrecking-hotel-rooms",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Nada Surf",
    "title": "Always Love",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "To make a mountain of your life\nIs just a choice\nBut I never learned enough\nTo listen to the voice that told me"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Always love, Hate will get you every time\nAlways love, Don’t wait til the finish line"
      },
      {
        "label": "",
        "index": 2,
        "text": "Slow demands come 'round\nSqueeze the air and keep the rest out\nIt helps to write it down\nEven when you then cross it out"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "But Always Love, Hate will get you every time\nAlways Love, Even when you wanna fight"
      },
      {
        "label": "",
        "index": 4,
        "text": "Self-directed lives\nI want to know what it'd be like to\nAim so high above\nAny card that you've been dealt, you..."
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Always Love, Hate will get you every time\nAlways Love, Hate will get you..."
      },
      {
        "label": "",
        "index": 6,
        "text": "I've been held back by something\nYeah. You said to me quietly on the stairs,\nI've been held back by something\nYeah. You said to me quietly on the stairs.\nYou said"
      },
      {
        "label": "",
        "index": 7,
        "text": "Hey, you good ones.\nHey, you…"
      }
    ],
    "id": "nada-surf-always-love",
    "capo": 2,
    "chords": [
      {
        "label": "Verse 1",
        "value": "Fmaj7/C C Em Am",
        "chords": [
          "Fmaj7/C",
          "C",
          "Em",
          "Am"
        ]
      },
      {
        "label": "Verse 2",
        "value": "Fmaj7/C C G",
        "chords": [
          "Fmaj7/C",
          "C",
          "G"
        ]
      },
      {
        "label": "Chorus",
        "value": "Fmaj7/C C G",
        "chords": [
          "Fmaj7/C",
          "C",
          "G"
        ]
      },
      {
        "label": "Bridge 1",
        "value": "Fmaj7/C C F6/D",
        "chords": [
          "Fmaj7/C",
          "C",
          "F6/D"
        ]
      },
      {
        "label": "Bridge 2",
        "value": "G Fmaj7/C C",
        "chords": [
          "G",
          "Fmaj7/C",
          "C"
        ]
      }
    ]
  },
  {
    "artist": "Nada Surf",
    "title": "Rushing",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I shut down\nI don't know why\nThought I didn't need\nAnother star in the sky"
      },
      {
        "label": "",
        "index": 1,
        "text": "I closed off\nThought I didn't have rules\nFor the risk it will take\nWhat I thought I could lose"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "You come rushing at me\nAnd I forget my body\nYou come rushing at me\nAnd I forget my body\nYou come rushing at me\nAnd I forget my worries"
      },
      {
        "label": "",
        "index": 3,
        "text": "Human river\nI watched it go by\nI set on the banks\nSolitary and dry"
      },
      {
        "label": "",
        "index": 4,
        "text": "I replayed\nThe films in my life\nThought I'd come to the end\nI told myself it was fine"
      },
      {
        "label": "Bridge",
        "index": 5,
        "text": "And you - you let me in and\nI - I let you in and\nWe - we are transported and"
      },
      {
        "label": "Bridge",
        "index": 6,
        "text": "You - you let me in and\nI - I let you in and\nWe - are elevated\nFinally I got it"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "You come rushing at me\nAnd I forget my body\nYou come rushing at me\nAnd I forget my body\nYou come rushing at me\nAnd I forget my worries"
      },
      {
        "label": "",
        "index": 8,
        "text": "I haven't landed\nSince I don't know when\nAnd now I feel like\nI can breathe again\nI see the street lights\nLand on your skin\nLand on your skin"
      },
      {
        "label": "Chorus",
        "index": 9,
        "text": "You come rushing at me\nAnd I forget my body\nYou come rushing at me\nAnd I forget my body\nYou come rushing at me\nAnd I forget my worries\nI haven't landed\nSince I don't know when\nAnd now I feel like\nI can breathe again"
      }
    ],
    "id": "nada-surf-rushing",
    "capo": 0,
    "chords": [
      {
        "label": "Intro",
        "value": "F C Am G",
        "chords": [
          "F",
          "C",
          "Am",
          "G"
        ]
      },
      {
        "label": "Verse",
        "value": "C F G Dm F",
        "chords": [
          "C",
          "F",
          "G",
          "Dm",
          "F"
        ]
      },
      {
        "label": "Chorus",
        "value": "F C Am G",
        "chords": [
          "F",
          "C",
          "Am",
          "G"
        ]
      }
    ]
  },
  {
    "artist": "Nirvana",
    "title": "About a Girl",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I need an easy friend\nI do, with an ear to lend\nI do think you fit this shoe\nI do, but you have a clue"
      },
      {
        "label": "",
        "index": 1,
        "text": "I'll take advantage while\nYou hang me out to dry\nBut I can't see you every night\nFree (I do, I do)"
      },
      {
        "label": "",
        "index": 2,
        "text": "I'm standing in your line\nI do hope you have the time\nI do pick a number too\nI do keep a date with you"
      },
      {
        "label": "",
        "index": 3,
        "text": "I'll take advantage while\nYou hang me out to dry\nBut I can't see you every night\nFree"
      },
      {
        "label": "",
        "index": 4,
        "text": "I need an easy friend\nI do, with an ear to lend\nI do think you fit this shoe\nI do, but you have a clue"
      },
      {
        "label": "",
        "index": 5,
        "text": "I'll take advantage while\nYou hang me out to dry\nBut I can't see you every night\nNo, I can't see you every night\nFree"
      },
      {
        "label": "",
        "index": 6,
        "text": "I do\nI do\nI do"
      }
    ],
    "id": "nirvana-about-a-girl",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Nirvana",
    "title": "Jesus Don't Want Me For A Sunbeam",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Jesus, doesn't want me for a sunbeam\nSunbeams are never made like me"
      },
      {
        "label": "",
        "index": 1,
        "text": "Don't expect me to cry\nFor all the reasons you had to die\nDon't ever ask your love of me"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Don't expect me to cry\nDon't expect me to lie\nDon't expect me to die for thee"
      },
      {
        "label": "",
        "index": 3,
        "text": "Jesus, don't want me for a sunbeam\nSunbeams are never made like me"
      },
      {
        "label": "",
        "index": 4,
        "text": "Don't expect me to cry\nFor all the reasons you had to die\nDon't ever ask your love of me"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Don't expect me to cry\nDon't expect me to lie\nDon't expect me to die for thee"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "Don't expect me to cry\nDon't expect me to lie\nDon't expect me to die for thee"
      },
      {
        "label": "",
        "index": 7,
        "text": "Jesus, don't want me for a sunbeam\nSunbeams are never made like me"
      },
      {
        "label": "",
        "index": 8,
        "text": "Don't expect me to cry\nFor all the reasons you had to die\nDon't ever ask your love of me"
      },
      {
        "label": "Chorus",
        "index": 9,
        "text": "Don't expect me to cry\nDon't expect me to lie\nDon't expect me to die\nDon't expect me to cry\nDon't expect me to lie\nDon't expect me to die for thee"
      }
    ],
    "id": "nirvana-jesus-dont-want-me-for-a-sunbeam",
    "capo": 1,
    "chords": [
      {
        "label": "Whole song",
        "value": "D C G",
        "chords": [
          "D",
          "C",
          "G"
        ]
      }
    ]
  },
  {
    "artist": "Nirvana",
    "title": "Oh, Me",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "If I had to lose a mile\nIf I had to touch feelings\nI would lose my soul\nThe way I do"
      },
      {
        "label": "",
        "index": 1,
        "text": "I don't have to think\nI only have to do it\nThe results are always perfect\nAnd that's old news"
      },
      {
        "label": "",
        "index": 2,
        "text": "Would you like to hear my voice\nSprinkled with emotion\nInvented at your birth?"
      },
      {
        "label": "",
        "index": 3,
        "text": "I can't see the end of me\nMy whole expanse I cannot see\nI formulate infinity\nStored deep inside me"
      },
      {
        "label": "",
        "index": 4,
        "text": "If I had to lose a mile\nIf I had to touch feelings\nI would lose my soul\nThe way I do"
      },
      {
        "label": "",
        "index": 5,
        "text": "I don't have to think\nI only have to do it\nThe results are always perfect\nAnd that's old news"
      },
      {
        "label": "",
        "index": 6,
        "text": "Would you like to hear my voice\nSprinkled with emotion\nInvented at your birth?"
      },
      {
        "label": "",
        "index": 7,
        "text": "I can't see the end of me\nMy whole expanse I cannot see"
      },
      {
        "label": "",
        "index": 8,
        "text": "I formulate infinity\nStored deep inside me"
      }
    ],
    "id": "nirvana-oh-me",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Nirvana",
    "title": "Where Did You Sleep Last Night",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "My girl, my girl, don't lie to me\nTell me where did you sleep last night\nIn the pines, in the pines where the sun don't ever shine\nI would shiver the whole night through"
      },
      {
        "label": "",
        "index": 1,
        "text": "My girl, my girl, where will you go?\nI'm going where the cold wind blows\nIn the pines, in the pines where the sun don't ever shine\nI would shiver the whole night through"
      },
      {
        "label": "",
        "index": 2,
        "text": "Her husband was a hard working man\nJust about a mile from here\nHis head was found in a driving wheel\nBut his body never was found"
      },
      {
        "label": "",
        "index": 3,
        "text": "My girl, my girl, don't lie to me\nTell me where did you sleep last night\nIn the pines, in the pines where the sun don't ever shine\nI would shiver the whole night through (Shiver for me)"
      },
      {
        "label": "",
        "index": 4,
        "text": "My girl, my girl, where will you go?\nI'm going where the cold wind blows\nIn the pines, in the pines where the sun don't ever shine\nI would shiver the whole night through"
      },
      {
        "label": "",
        "index": 5,
        "text": "My girl, my girl, don't lie to me\nTell me where did you sleep last night\nIn the pines, in the pines where the sun don't ever shine\nI would shiver the whole night through"
      },
      {
        "label": "",
        "index": 6,
        "text": "My girl, my girl, where will you go?\nI'm going where the cold wind blows\nIn the pines, the pines the sun don't shine\nI'd shiver... the whole night through"
      }
    ],
    "id": "nirvana-where-did-you-sleep-last-night",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "No Use For A Name",
    "title": "Black Box",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Up here sedated in my seat,\nCan't wait until I can see my feet\nAnd run away from stupid things I've said"
      },
      {
        "label": "",
        "index": 1,
        "text": "I'll be here a few days from now,\nWe'll talk about it when I come down\nIf we can make it this time, \nI'll clean the stain I've bled"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Our worlds collide in new beginnings\nIt's an emergency permission to bail out\nSo when the heart crash lands,\nWhat memories will survive?\nI thank my black box that I'm alive"
      },
      {
        "label": "",
        "index": 3,
        "text": "I feel unlucky today,\nCan't wash the panic off my face\nDidn't learn a thing from the last time"
      },
      {
        "label": "",
        "index": 4,
        "text": "It’s so selfish to be so confused,\nHeart beats like rapid fire\nSo close to the edge but far away in mind"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Two days to go until I see you\nLooks like we can call off the ambulance down there\nMy head is touching down I've learned to be afraid\nI thank my black box that you're the same"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "Our worlds collide in new beginnings\nIt's an emergency permission to bail out\nSo when the heart crash lands,\nWhat memories will survive?\nI thank my black box that I'm alive"
      },
      {
        "label": "",
        "index": 7,
        "text": "I feel unlucky today"
      }
    ],
    "id": "no-use-for-a-name-black-box",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "No Use For A Name",
    "title": "Let Me Down",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "She's never alone\nbecause she's scared of what she might say to herself\nAlways drinking in the backroom of the bar \nwhere everyone turns in\nA half-hearted grin"
      },
      {
        "label": "",
        "index": 1,
        "text": "She won't be afraid \nas long as that prescription keeps going through\nAnd all the happy pills make her look like cardboard \ncut out of someone\nI use to learn from"
      },
      {
        "label": "",
        "index": 2,
        "text": "But on the phone, she's telling everyone,\nthat there was a blue sky, she left behind\nAnd there's a place that no one knows about\nAway from integrity \nshe writes a book in her head that nobody will read"
      },
      {
        "label": "",
        "index": 3,
        "text": "Whatever you say\nplease don't talk about the time when she was young\nApparently that was a different person \nand so long ago it's strange to me, there's no history"
      },
      {
        "label": "",
        "index": 4,
        "text": "But there's a past \nand she's telling everyone It must be a garden,\nthat wouldn't grow\nWith roots of shame, too sensitive to blame \nto herself as we watch he drown,\nI can't save the queen without a kingdom or a crown"
      },
      {
        "label": "",
        "index": 5,
        "text": "Somewhere in this lonely game of sympathy \nthere is a selfish dream\nThat makes me sick\nStanding on the high wire while you're on the ground\nTo you what is dangerous is safe and sound...\nYou let me down"
      }
    ],
    "id": "no-use-for-a-name-let-me-down",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "NOFX",
    "title": "Scavenger Type",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Gigin alone at the bottom of the hill\nOur protagonist named Bill\nSets his sights on an anchor steam pint\nAll he, needs is thirteen quarters\nCongregated in his hat"
      },
      {
        "label": "",
        "index": 1,
        "text": "A crow, a scavenger type\nCalifornia redemption, provides him with his rent\nRoom and board inside of, a fifth, of comfort"
      },
      {
        "label": "",
        "index": 2,
        "text": "As the wind penetrates his bones\nHis mind keep focused\nTidal waves of sound catapulted\nFrom his horn, wail like lovers"
      },
      {
        "label": "",
        "index": 3,
        "text": "The coins don't drop consistent as does the mercury\nHis meter slows realizing a zenith\nHe's reached perfection\nNo one did see him die"
      }
    ],
    "id": "nofx-scavenger-type",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Nothing To Lose",
    "title": "The Missing Pieces",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I'll say it again\nJust one more time\nFirst listen then\nMake up your mind"
      },
      {
        "label": "Pre Chorus",
        "index": 1,
        "text": "So long\nIf you think I'll wait for you\nNothing personal but don't take all day\nSince you've been here\nI'm sure that I'll wait for you\nI still know that it's the wrong kind of way"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Cause there's something wrong without you\nAnd to get ahead I need you\nIt's not easy living without you\nCome back again\nDon't want to hear that line\n\"we're better off as friends\""
      },
      {
        "label": "",
        "index": 3,
        "text": "You're coming along\nJust like before\nThis time for you\nIt's not much more"
      },
      {
        "label": "Pre Chorus",
        "index": 4,
        "text": "So long\nIf you think I'll wait for you\nNothing personal but dont take all day\nSince you've been here\nI'm sure that I'll wait for you\nI still know that it's the wrong kind of way"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Cause there's something wrong without you\nAnd to get ahead I need you\nIt's not easy living without you\nCome back again\nDon't want to hear that line\n\"we're better off as friends\""
      },
      {
        "label": "",
        "index": 6,
        "text": "I will pull those tears right from your eyes\nAnd make sure that the roots will die\nThis drama is tearing us down\nThis taste I get from just your lips\nMakes everything seem meaningless\nI promise I want you around\nYou're not around"
      }
    ],
    "id": "nothing-to-lose-the-missing-pieces",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Oasis",
    "title": "Crying Your Heart Out",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Hold up\nHold on\nDon't be scared\nYou'll never change what's been and gone"
      },
      {
        "label": "",
        "index": 1,
        "text": "May your smile (may your smile)\nShine on (shine on)\nDon't be scared (don't be scared)\nYour destiny may keep you warm"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "'Cause all of the stars are fading away\nJust try not to worry, you'll see them someday\nTake what you need, and be on your way\nAnd stop crying your heart out"
      },
      {
        "label": "",
        "index": 3,
        "text": "Get up (get up)\nCome on (come on)\nWhy're you scared? (I'm not scared)\nYou'll never change what's been and gone"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "'Cause all of the stars are fading away\nJust try not to worry, you'll see them someday\nTake what you need, and be on your way\nAnd stop crying your heart out"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "'Cause all of the stars are fading away\nJust try not to worry, you'll see them someday\nJust take what you need, and be on your way\nAnd stop crying your heart out"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "We're all of the stars, we're fading away\nJust try not to worry, you'll see us some day\nJust take what you need, and be on your way\nAnd stop crying your heart out"
      },
      {
        "label": "",
        "index": 7,
        "text": "Stop crying your heart out\nStop crying your heart out\nStop crying your heart out"
      }
    ],
    "id": "oasis-crying-your-heart-out",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Oasis",
    "title": "Don't Look Back In Anger",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "Slip inside the eye of your mind\nDon't you know you might find\nA better place to play?\nYou said that you'd never been\nBut all the things that you've seen\nSlowly fade away"
      },
      {
        "label": "Pre-Chorus",
        "index": 1,
        "text": "So I start a revolution from my bed\n'Cause you said the brains I had went to my head\nStep outside, summertime's in bloom\nStand up beside the fireplace\nTake that look from off your face\nYou ain't ever gonna burn my heart out"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "And so, Sally can wait\nShe knows it's too late as we're walkin' on by\nHer soul slides away\nBut \"Don't look back in anger,\" I heard you say"
      },
      {
        "label": "Verse 2",
        "index": 3,
        "text": "Take me to the place where you go\nWhere nobody knows\nIf it's night or day\nPlease don't put your life in the hands\nOf a rock 'n' roll band\nWho'll throw it all away"
      },
      {
        "label": "Pre-Chorus",
        "index": 4,
        "text": "I'm gonna start a revolution from my bed\n'Cause you said the brains I had went to my head\nStep outside, 'cause summertime's in bloom\nStand up beside the fireplace\nTake that look from off your face\n'Cause you ain't ever gonna burn my heart out"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "And so, Sally can wait\nShe knows it's too late as she's walking on by\nMy soul slides away\nBut \"Don't look back in anger,\" I heard you say"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "So, Sally can wait\nShe knows it's too late as we're walking on by\nHer soul slides away\nBut \"Don't look back in anger,\" I heard you say"
      },
      {
        "label": "Outro",
        "index": 7,
        "text": "So, Sally can wait\nShe knows it's too late as she's walking on by\nMy soul slides away\nBut \"Don't look back in anger, don't look back in anger\"\nI heard you say\n\"At least not today\""
      }
    ],
    "id": "oasis-dont-look-back-in-anger",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Oasis",
    "title": "Stand by Me",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Made a meal and threw it up on Sunday\nI've got a lot of things to learn\nSaid I would and I'll be leaving one day\nBefore my heart starts to burn"
      },
      {
        "label": "",
        "index": 1,
        "text": "So what's the matter with you?\nSing me something new\nDon't you know, the cold and wind and rain don't know\nThey only seem to come and go away"
      },
      {
        "label": "",
        "index": 2,
        "text": "Times are hard when things have got no meaning\nI've found a key upon the floor\nMaybe you and I will not believe in\nThe things we find behind the door"
      },
      {
        "label": "",
        "index": 3,
        "text": "So what's the matter with you?\nSing me something new\nDon't you know, the cold and wind and rain don't know\nThey only seem to come and go away"
      },
      {
        "label": "",
        "index": 4,
        "text": "Stand by me\nNobody knows the way it's gonna be\nYeah, nobody knows\nThe way it's gonna be"
      },
      {
        "label": "",
        "index": 5,
        "text": "If you're leaving, will you take me with you?\nI'm tired of talking on my phone\nThere is one thing I can never give you\nMy heart will never be your home"
      },
      {
        "label": "",
        "index": 6,
        "text": "So what's the matter with you?\nSing me something new\nDon't you know, the cold and wind and rain don't know\nThey only seem to come and go away\n(Hey, hey)"
      },
      {
        "label": "",
        "index": 7,
        "text": "Stand by me\nNobody knows the way it's gonna be\nYeah, nobody knows\nThe way it's gonna be"
      },
      {
        "label": "",
        "index": 8,
        "text": "The way it's gonna be, yeah\nMaybe I can see, yeah\nDon't you know, the cold and wind and rain don't know\nThey only seem to come and go away\n(Hey, hey)"
      },
      {
        "label": "",
        "index": 9,
        "text": "Stand by me\nNobody knows the way it's gonna be\nYeah, nobody knows\nThe way it's gonna be"
      }
    ],
    "id": "oasis-stand-by-me",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Oasis",
    "title": "Wonderwall",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Today is gonna be the day\nThat they're gonna throw it back to you\nBy now you should've somehow\nRealized what you gotta do\nI don't believe that anybody\nFeels the way I do, about you now"
      },
      {
        "label": "",
        "index": 1,
        "text": "Backbeat, the word was on the street\nThat the fire in your heart is out\nI'm sure you've heard it all before\nBut you never really had a doubt\nI don't believe that anybody\nFeels the way I do about you now"
      },
      {
        "label": "",
        "index": 2,
        "text": "And all the roads we have to walk are winding\nAnd all the lights that lead us there are blinding\nThere are many things that I\nWould like to say to you but I don't know how"
      },
      {
        "label": "",
        "index": 3,
        "text": "Because maybe, you're gonna be the one that saves me\nAnd after all, you're my wonderwall"
      },
      {
        "label": "",
        "index": 4,
        "text": "Today was gonna be the day\nBut they'll never throw it back to you\nBy now you should've somehow\nRealized what you're not to do\nI don't believe that anybody\nFeels the way I do, about you now"
      },
      {
        "label": "",
        "index": 5,
        "text": "And all the roads that lead you there are winding\nAnd all the lights that light the way are blinding\nThere are many things that I\nWould like to say to you but I don't know how"
      },
      {
        "label": "",
        "index": 6,
        "text": "I said maybe, you're gonna be the one that saves me\nAnd after all, you're my wonderwall"
      },
      {
        "label": "",
        "index": 7,
        "text": "I said maybe, you're gonna be the one that saves me\nAnd after all, you're my wonderwall"
      },
      {
        "label": "",
        "index": 8,
        "text": "I said maybe, you're gonna be the one that saves me\nYou're gonna be the one that saves me"
      }
    ],
    "id": "oasis-wonderwall",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Olivia Rodrigo",
    "title": "Deja Vu",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Car rides to Malibu\nStrawberry ice cream\nOne spoon for two\nAnd trading jackets\nLaughing 'bout how small it looks on you\n(Ha-ha-ha-ha, ha-ha-ha-ha, ha-ha-ha-ha)"
      },
      {
        "label": "",
        "index": 1,
        "text": "Watching reruns of Glee\nBeing annoying\nSinging in harmony\nI bet she's bragging\nTo all her friends, saying you're so unique, hmm"
      },
      {
        "label": "",
        "index": 2,
        "text": "So when you gonna tell her\nThat we did that, too?\nShe thinks it's special\nBut it's all reused\nThat was our place, I found it first\nI made the jokes you tell to her when she's with you"
      },
      {
        "label": "",
        "index": 3,
        "text": "Do you get déjà vu when she's with you?\nDo you get déjà vu? (Ah), hmm\nDo you get déjà vu, huh?"
      },
      {
        "label": "",
        "index": 4,
        "text": "Do you call her\nAlmost say my name?\n'Cause let's be honest\nWe kinda do sound the same\nAnother actress\nI hate to think that I was just your type"
      },
      {
        "label": "",
        "index": 5,
        "text": "I'll bet that she knows Billy Joel\n'Cause you played her \"Uptown Girl\"\nYou're singing it together\nNow I bet you even tell her\nHow you love her\nIn between the chorus and the verse (ooh) (I love you)"
      },
      {
        "label": "",
        "index": 6,
        "text": "So when you gonna tell her\nThat we did that, too?\nShe thinks it's special\nBut it's all reused\nThat was the show we talked about\nPlayed you the song she's singing now when she's with you"
      },
      {
        "label": "",
        "index": 7,
        "text": "Do you get déjà vu when she's with you?\nDo you get déjà vu? Oh\nDo you get déjà vu?"
      },
      {
        "label": "",
        "index": 8,
        "text": "Strawberry ice cream in Malibu\nDon't act like we didn't do that shit, too\nYou're trading jackets like we used to do\n(Yeah, everything is all reused)"
      },
      {
        "label": "",
        "index": 9,
        "text": "Play her piano, but she doesn't know (oh, oh)\nThat I was the one who taught you Billy Joel (oh)\nA different girl now, but there's nothing new\n(I know you get déjà vu)"
      }
    ],
    "id": "olivia-rodrigo-deja-vu",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Ozzy Osbourne",
    "title": "Changes",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "I feel unhappy, I feel so sad\nI've lost the best friend that I ever had\nShe was my woman, I loved her so\nBut it's too late now, I've let her go"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "I'm going through changes\nI'm going through changes"
      },
      {
        "label": "Verse",
        "index": 2,
        "text": "We shared the years, we shared each day\nIn love together, we found the way\nBut soon the world had its evil way\nMy heart was blinded, love went astray"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "I'm going through changes\nI'm going through changes"
      },
      {
        "label": "Verse",
        "index": 4,
        "text": "It took so long to realise\nAnd I can still hear her last goodbyes\nNow all my days are filled with tears\nWish I could go back and change these years"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "I'm going through changes\nI'm going through changes"
      }
    ],
    "id": "ozzy-osbourne-changes",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Ozzy Osbourne",
    "title": "Mama, I'm Coming Home",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Times have changed and times are strange\nHere I come, but I ain't the same\nMama, I'm coming home\nTime's gone by, it seems to be\nYou could have been a better friend to me\nMama, I'm coming home"
      },
      {
        "label": "",
        "index": 1,
        "text": "You took me in and you drove me out\nYeah, you had me hypnotized, yeah\nLost and found and turned around\nBy the fire in your eyes"
      },
      {
        "label": "",
        "index": 2,
        "text": "You made me cry, you told me lies\nBut I can't stand to say goodbye\nMama, I'm coming home\nI could be right, I could be wrong\nIt hurts so bad, it's been so long\nMama, I'm coming home"
      },
      {
        "label": "",
        "index": 3,
        "text": "Selfish love, yeah, we're both alone\nThe ride before the fall, yeah\nBut I'm gonna take this heart of stone\nI've just got to have it all"
      },
      {
        "label": "",
        "index": 4,
        "text": "I've seen your face a hundred times\nEvery day we've been apart\nI don't care about the sunshine, yeah\n'Cause Mama, Mama, I'm coming home\nI'm coming home"
      },
      {
        "label": "Guitar Solo",
        "index": 5,
        "text": ""
      },
      {
        "label": "",
        "index": 6,
        "text": "You took me in and you drove me out\nYeah, you had me hypnotized, yeah\nLost and found and turned around\nBy the fire in your eyes"
      },
      {
        "label": "",
        "index": 7,
        "text": "I've seen your face a thousand times\nEvery day we've been apart\nI don't care about the sunshine, yeah\n'Cause Mama, Mama I'm coming home\nI'm coming home\nI'm coming home\nI'm coming home"
      }
    ],
    "id": "ozzy-osbourne-mama-im-coming-home",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Paramore",
    "title": "Hate To See Your Heart Break",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "There is not a single word in the whole world\nThat could describe the hurt\nThe dullest knife just sawing back and forth\nAnd ripping through the softest skin there ever was"
      },
      {
        "label": "",
        "index": 1,
        "text": "How were you to know?\nOh, how were you to know?"
      },
      {
        "label": "",
        "index": 2,
        "text": "And I, I hate to see your heart break\nI hate to see your eyes get darker as they close\nBut I've been there before\nAnd I, I hate to see your heart break\nI hate to see your eyes get darker as they close\nBut I've been there before"
      },
      {
        "label": "",
        "index": 3,
        "text": "Love, happens all the time\nTo people who aren't kind\nAnd heroes who are blind\nExpecting perfect scripted movie scenes\nBut what's an awkward silence mystery?"
      },
      {
        "label": "",
        "index": 4,
        "text": "How were you to know?\nOh, how were you to know?"
      },
      {
        "label": "",
        "index": 5,
        "text": "And I, I hate to see your heart break\nI hate to see your eyes get darker as they close\nBut I've been there before\nAnd I, I hate to see your heart break\nI hate to see your eyes get darker as they close\nBut I've been there before"
      },
      {
        "label": "",
        "index": 6,
        "text": "For all the air that's in your lungs\nFor all the joy that is to come\nFor all the things that you're alive to feel\nJust let the pain remind you hearts can heal"
      },
      {
        "label": "",
        "index": 7,
        "text": "Oh, how were you to know?\nOh, how were you to know?"
      },
      {
        "label": "",
        "index": 8,
        "text": "And I, I hate to see your heart break\nI hate to see your eyes get darker as they close\nBut I've been there before\nAnd I, I hate to see your heart break\nI hate to see your eyes get darker as they close\nBut I've been there before"
      }
    ],
    "id": "paramore-hate-to-see-your-heart-break",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Paramore",
    "title": "The Only Exception",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "When I was younger I saw my daddy cry\nAnd curse at the wind\nHe broke his own heart and I watched\nAs he tried to reassemble it"
      },
      {
        "label": "",
        "index": 1,
        "text": "And my momma swore\nThat she would never let herself forget\nAnd that was the day that I promised\nI'd never sing of love if it does not exist"
      },
      {
        "label": "",
        "index": 2,
        "text": "But darling\nYou are the only exception\nYou are the only exception\nYou are the only exception\nYou are the only exception"
      },
      {
        "label": "",
        "index": 3,
        "text": "Maybe I know somewhere deep in my soul\nThat love never lasts\nAnd we've got to find other ways to make it alone\nOr keep a straight face"
      },
      {
        "label": "",
        "index": 4,
        "text": "And I've always lived like this\nKeeping a comfortable distance\nAnd up until now I had sworn to myself\nThat I'm content with loneliness"
      },
      {
        "label": "",
        "index": 5,
        "text": "Because none of it was ever worth the risk"
      },
      {
        "label": "",
        "index": 6,
        "text": "Well, you are the only exception\nYou are the only exception\nYou are the only exception\nYou are the only exception"
      },
      {
        "label": "",
        "index": 7,
        "text": "I've got a tight grip on reality\nBut I can't let go of what's in front of me here\nI know you're leaving in the morning when you wake up\nLeave me with some kind of proof it's not a dream\nOh-oh-oh-oh"
      },
      {
        "label": "",
        "index": 8,
        "text": "You are the only exception\nYou are the only exception\nYou are the only exception\nYou are the only exception"
      },
      {
        "label": "",
        "index": 9,
        "text": "You are the only exception\nYou are the only exception\nYou are the only exception\nYou are the only exception"
      },
      {
        "label": "",
        "index": 10,
        "text": "And I'm on my way to believing\nOh, and I'm on my way to believing"
      }
    ],
    "id": "paramore-the-only-exception",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Pearl Jam",
    "title": "Black",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Sheets of empty canvas\nUntouched sheets of clay\nWere laid spread out before me\nAs her body once did"
      },
      {
        "label": "",
        "index": 1,
        "text": "All five horizons\nRevolved around her soul\nAs the earth to the sun\nNow the air I tasted and breathed\nHas taken a turn"
      },
      {
        "label": "Pre-Chorus",
        "index": 2,
        "text": "Oh and all I taught her was everything\nOh I know she gave me all that she wore"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "And now my bitter hands\nChafe beneath the clouds\nOf what was everything\nOh the pictures have\nAll been washed in black\nTattooed everything"
      },
      {
        "label": "",
        "index": 4,
        "text": "I take a walk outside\nI'm surrounded by\nSome kids at play\nI can feel their laughter\nSo why do I sear"
      },
      {
        "label": "Pre-Chorus",
        "index": 5,
        "text": "Oh, and twisted thoughts that spin\nRound my head\nI'm spinning\nOh, I'm spinning\nHow quick the sun can, drop away"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "And now my bitter hands\nCradle broken glass\nOf what was everything\nAll the pictures had\nAll been washed in black\nTattooed everything"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "All the love gone bad\nTurned my world to black\nTattooed all I see\nAll that I am\nAll I'll be\nYeah"
      },
      {
        "label": "Bridge",
        "index": 8,
        "text": "I know someday you'll have a beautiful life\nI know you'll be a star\nIn somebody else's sky\nBut why\nWhy\nWhy can't it be\nWhy can't it be mine"
      }
    ],
    "id": "pearl-jam-black",
    "capo": 0,
    "chords": [
      {
        "label": "Verse",
        "value": "G A7sus4",
        "chords": [
          "G",
          "A7sus4"
        ]
      },
      {
        "label": "Chorus/Pre-Chorus/Bridge",
        "value": "Em D A7sus4",
        "chords": [
          "Em",
          "D",
          "A7sus4"
        ]
      }
    ]
  },
  {
    "artist": "R.E.M.",
    "title": "Losing My Religion",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Oh, life is bigger\nIt's bigger\nThan you and you are not me\nThe lengths that I will go to\nThe distance in your eyes\nOh no, I've said too much\nI set it up"
      },
      {
        "label": "",
        "index": 1,
        "text": "That's me in the corner\nThat's me in the spotlight\nLosing my religion\nTrying to keep up with you\nAnd I don't know if I can do it\nOh no, I've said too much\nI haven't said enough"
      },
      {
        "label": "",
        "index": 2,
        "text": "I thought that I heard you laughing\nI thought that I heard you sing\nI think I thought I saw you try"
      },
      {
        "label": "",
        "index": 3,
        "text": "Every whisper\nOf every waking hour\nI'm choosing my confessions\nTrying to keep an eye on you\nLike a hurt lost and blinded fool, fool\nOh no, I've said too much\nI set it up"
      },
      {
        "label": "",
        "index": 4,
        "text": "Consider this\nConsider this\nThe hint of the century\nConsider this\nThe slip that brought me\nTo my knees, failed\nWhat if all these fantasies\nCome flailing around?\nNow I've said too much"
      },
      {
        "label": "",
        "index": 5,
        "text": "I thought that I heard you laughing\nI thought that I heard you sing\nI think I thought I saw you try"
      },
      {
        "label": "",
        "index": 6,
        "text": "But that was just a dream\nThat was just a dream"
      },
      {
        "label": "",
        "index": 7,
        "text": "That's me in the corner\nThat's me in the spotlight\nLosing my religion\nTrying to keep up with you\nAnd I don't know if I can do it\nOh no, I've said too much\nI haven't said enough"
      },
      {
        "label": "",
        "index": 8,
        "text": "I thought that I heard you laughing\nI thought that I heard you sing\nI think I thought I saw you try"
      },
      {
        "label": "",
        "index": 9,
        "text": "But that was just a dream\nTry, cry\nWhy try?\nThat was just a dream, just a dream, just a dream"
      }
    ],
    "id": "rem-losing-my-religion",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "R.E.M.",
    "title": "Shiny Happy People",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Shiny happy people laughing"
      },
      {
        "label": "",
        "index": 1,
        "text": "Meet me in the crowd, people, people\nThrow your love around, love me, love me\nTake it into town, happy, happy\nPut it in the ground where the flowers grow\nGold and silver shine"
      },
      {
        "label": "",
        "index": 2,
        "text": "Shiny happy people holding hands\nShiny happy people holding hands\nShiny happy people laughing"
      },
      {
        "label": "",
        "index": 3,
        "text": "Everyone around, love them, love them\nPut it in your hands, take it, take it\nThere's no time to cry, happy, happy\nPut it in your heart where tomorrow shines\nGold and silver shine"
      },
      {
        "label": "",
        "index": 4,
        "text": "Shiny happy people holding hands\nShiny happy people holding hands\nShiny happy people laughing"
      },
      {
        "label": "",
        "index": 5,
        "text": "Whoa, here we go"
      },
      {
        "label": "",
        "index": 6,
        "text": "Shiny happy people holding hands\nShiny happy people holding hands\nShiny happy people laughing"
      },
      {
        "label": "",
        "index": 7,
        "text": "Shiny happy people holding hands\nShiny happy people holding hands\nShiny happy people laughing"
      },
      {
        "label": "",
        "index": 8,
        "text": "Shiny happy people holding hands\nShiny happy people holding hands\nShiny happy people laughing"
      },
      {
        "label": "",
        "index": 9,
        "text": "Shiny happy people holding hands (people, happy people)\nShiny happy people holding hands (people, happy people)\nShiny happy people holding hands (people, happy people)"
      }
    ],
    "id": "rem-shiny-happy-people",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "R.E.M.",
    "title": "The One I Love",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "This one goes out to the one I love\nThis one goes out to the one I've left behind\nA simple prop to occupy my time\nThis one goes out to the one I love"
      },
      {
        "label": "",
        "index": 1,
        "text": "Fire"
      },
      {
        "label": "",
        "index": 2,
        "text": "This one goes out to the one I love\nThis one goes out to the one I've left behind\nA simple prop to occupy my time\nThis one goes out to the one I love"
      },
      {
        "label": "",
        "index": 3,
        "text": "Fire (she's comin' down on her own, now)\nFire (she's comin' down on her own)"
      },
      {
        "label": "",
        "index": 4,
        "text": "This one goes out to the one I love\nThis one goes out to the one I've left behind\nAnother prop has occupied my time\nThis one goes out to the one I love"
      },
      {
        "label": "",
        "index": 5,
        "text": "Fire (she's comin' down on her own, now)\nFire (she's comin' down on her own)\nFire (she's comin' down on her own, now)\nFire (she's comin' down on her own)"
      }
    ],
    "id": "rem-the-one-i-love",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Radiohead",
    "title": "Creep",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "When you were here before\nCouldn't look you in the eye\nYou're just like an angel\nYour skin makes me cry\nYou float like a feather\nIn a beautiful world\nI wish I was special\nYou're so fuckin' special"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "But I'm a creep, I'm a weirdo\nWhat the hell am I doin' here?\nI don't belong here"
      },
      {
        "label": "Verse 2",
        "index": 2,
        "text": "I don't care if it hurts\nI wanna have control\nI want a perfect body\nI want a perfect soul\nI want you to notice\nWhen I'm not around\nYou're so fuckin' special\nI wish I was special"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "But I'm a creep, I'm a weirdo\nWhat the hell am I doin' here?\nI don't belong here\nOh-oh, oh-oh"
      },
      {
        "label": "Bridge",
        "index": 4,
        "text": "She's runnin' out the door\nShe's runnin' out\nShe run, run, run, run\nRun"
      },
      {
        "label": "Outro",
        "index": 5,
        "text": "Whatever makes you happy\nWhatever you want\nYou're so fuckin' special\nI wish I was special\nBut I'm a creep, I'm a weirdo\nWhat the hell am I doin' here?\nI don't belong here\nI don't belong here"
      }
    ],
    "id": "radiohead-creep",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Radiohead",
    "title": "Fake Plastic Trees",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "A green plastic watering can\nFor a fake Chinese rubber plant\nIn a fake plastic earth\nThat she bought from a rubber man\nIn a town full of rubber plants\nTo get rid of itself"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "It wears her out\nIt wears her out\nIt wears her out\nIt wears her out"
      },
      {
        "label": "Verse 2",
        "index": 2,
        "text": "She lives with a broken man\nA cracked polystyrene man\nWho just crumbles and burns\nHe used to do surgery\nFor girls in the eighties\nBut gravity always wins"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "And it wears him out\nIt wears him out\nIt wears him out\nIt wears"
      },
      {
        "label": "Verse 3",
        "index": 4,
        "text": "She looks like the real thing\nShe tastes like the real thing\nMy fake plastic love\nBut I can't help the feeling\nI could blow through the ceiling\nIf I just turn and run"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "And it wears me out\nIt wears me out\nIt wears me out\nIt wears me out"
      },
      {
        "label": "Outro",
        "index": 6,
        "text": "And if I could be who you wanted\nIf I could be who you wanted\nAll the time\nAll the time"
      }
    ],
    "id": "radiohead-fake-plastic-trees",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Red Hot Chili Peppers",
    "title": "Under The Bridge",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Sometimes I feel like I don't have a partner\nSometimes I feel like my only friend\nIs the city I live in, the city of angels\nLonely as I am, together we cry\nI drive on her streets 'cause she's my companion\nI walk through her hills 'cause she knows who I am\nShe sees my good deeds and she kisses me windy\nWell, I never worry, now that is a lie"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "I don't ever wanna feel\nLike I did that day\nTake me to the place I love\nTake me all the way\nI don't ever wanna feel\nLike I did that day\nTake me to the place I love\nTake me all the way\nYeah, yeah, yeah"
      },
      {
        "label": "",
        "index": 2,
        "text": "It's hard to believe that there's nobody out there\nIt's hard to believe that I'm all alone\nAt least I have her love, the city, she loves me\nLonely as I am, together we cry"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "I don't ever wanna feel\nLike I did that day\nTake me to the place I love\nTake me all the way\nI don't ever wanna feel\nLike I did that day\nTake me to the place I love\nTake me all the way"
      },
      {
        "label": "",
        "index": 4,
        "text": "Yeah, yeah, yeah\nOh, no, no-no, yeah, yeah\nLove me, I say, yeah yeah\nOne time"
      },
      {
        "label": "",
        "index": 5,
        "text": "Is where I drew some blood\n(Under the bridge downtown)\nI could not get enough\n(Under the bridge downtown)\nForgot about my love\n(Under the bridge downtown)\nI gave my life away"
      },
      {
        "label": "",
        "index": 6,
        "text": "Yeah, yeah\nOh, no, no-no-no, yeah, yeah\nOh, no, I said, oh, yeah, yeah\nWhere I stay"
      }
    ],
    "id": "red-hot-chili-peppers",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Roxette",
    "title": "Listen To Your Heart",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "I know there's something in the wake of your smile\nI get a notion from the look in your eyes, yeah\nYou've built a love, but that love falls apart\nYour little piece of Heaven turns too dark"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Listen to your heart\nWhen he's calling for you\nListen to your heart\nThere's nothing else you can do"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "I don't know where you're going\nAnd I don't know why\nBut listen to your heart\nBefore you tell him goodbye"
      },
      {
        "label": "Verse 2",
        "index": 3,
        "text": "Sometimes you wonder if this fight is worthwhile\nThe precious moments are all lost in the tide, yeah\nThey're swept away, and nothing is what is seems\nThe feeling of belonging to your dreams"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "Listen to your heart\nWhen he's calling for you\nListen to your heart\nThere's nothing else you can do"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "I don't know where you're going\nAnd I don't know why\nBut listen to your heart\nBefore you tell him goodbye"
      },
      {
        "label": "Bridge",
        "index": 6,
        "text": "And there are voices that want to be heard\nSo much to mention but you can't find the words\nThe scent of magic, the beauty that's been\nWhen love was wilder than the wind"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "Listen to your heart (Take a listen to it)\nWhen he's calling for you\nListen to your heart (Take a listen to it)\nThere's nothing else you can do"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "I don't know where you're going\nAnd I don't know why\nBut listen to your heart before, uhh"
      },
      {
        "label": "Chorus",
        "index": 9,
        "text": "Listen to your heart (Take a listen to it)\nWhen he's calling for you\nListen to your heart (Take a listen to it)\nThere's nothing else you can do"
      },
      {
        "label": "Chorus",
        "index": 10,
        "text": "I don't know where you're going\nAnd I don't know why\nBut listen to your heart\nBefore you tell him goodbye"
      }
    ],
    "id": "roxette-listen-to-your-heart",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "Around You",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I'm on the way home, I'm on the way home to you\nSo take off your make up, take off your make up, would you?\n‘Cause we’re gonna wake up, we’re gonna wake up\nThe night is strange and new\n‘Cause love is soaking through"
      },
      {
        "label": "",
        "index": 1,
        "text": "I want to wake up, I want to wake up to you\nI want to wake up, I want to wake up to you\n‘Cause we’re gonna wake up, we’re gonna wake up\nThe night is strange and new\n‘Cause love is soaking through\nAnd in a daze, I'll run to you\nAnd I will throw my arms around you"
      },
      {
        "label": "",
        "index": 2,
        "text": "I'll throw my arms around you."
      }
    ],
    "id": "sherwood-around-you",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "Ground Beneath My Feet",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I saw the light dance on your face\nPaint the shadows of your shape\nBack when the season made the days, so long"
      },
      {
        "label": "",
        "index": 1,
        "text": "And if I knew my tide would come\nWith lines rehearsed but never sung\nI'd leave the chorus on your tongue, I swear"
      },
      {
        "label": "",
        "index": 2,
        "text": "Now I look around to see the ground beneath my feet\nEvery second takes a thousands days away from me\nIf I close my eyes, I hear the gates swing wide\nAnd by morning light, I will have seen both sides"
      },
      {
        "label": "",
        "index": 3,
        "text": "I got a funny feeling that it’s my day to leave\nShoulders down, the sweat drips across my cheek\nAs I turn I can taste the salt in the shallow sea\nI feel the hearts beating next to me\nI hear the chatter of my teeth"
      },
      {
        "label": "",
        "index": 4,
        "text": "And I look around to see the ground beneath my feet\nThe ﬁnal thrill, the quiet spill of our retreat\nDo you see the waves running away from me?\nCan you taste the cold here in the air I breathe?\nWhy’d you let me go?\n‘Cause I can no longer see"
      },
      {
        "label": "",
        "index": 5,
        "text": "And I scream,\nOh. My. God."
      },
      {
        "label": "",
        "index": 6,
        "text": "Now I look around to see the ground beneath my feet\nEvery second takes a thousands days away from me\nIf I close my eyes,\nI hear the gates swing wide\nAnd by morning light,\nI will have seen both sides\nSo why’d you let me go?\nI can no longer see,\nAnd I can’t breathe."
      }
    ],
    "id": "sherwood-ground-beneath-my-feet",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "Last To Know",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Every single time\nI see you in the street as you walk by\nYou're shuffling your feet as if to say, you'd rather die"
      },
      {
        "label": "",
        "index": 1,
        "text": "Then live so far from home\nI wonder is that why you're so alone\nAnd if you found a place to call you own, would you be fine?"
      },
      {
        "label": "",
        "index": 2,
        "text": "And you tell me that something good is bound to happen soon\nAnd meanwhile you'll just wait inside your room"
      },
      {
        "label": "",
        "index": 3,
        "text": "Do you find it odd\nThat you are not as strong as you once thought?\nAnd even if you run you might get caught, so you won't go\nAnd you'll be the last to know"
      },
      {
        "label": "",
        "index": 4,
        "text": "And even if you run you might get caught, so you won't go\nAnd you'll be the last to know"
      }
    ],
    "id": "sherwood-last-to-know",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "Make It Through",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I know exactly where you are\nSo I don't strain to see \nyour light shine in the dark"
      },
      {
        "label": "",
        "index": 1,
        "text": "But I didn't know, \nthe winter cold would leave us moving so slow\nAnd will summer bring the feeling back to our toes?\nUntil it does can I just make it through?"
      },
      {
        "label": "",
        "index": 2,
        "text": "Cause I don't know where I've gone wrong\nAnd I can't find where I belong without you\nMake it through\nCause I don't know where I've gone wrong\nAnd I can't find where I belong without you"
      },
      {
        "label": "",
        "index": 3,
        "text": "I'll weave the roads on every map\nInto a cord that wraps you up \nAnd pulls you back"
      },
      {
        "label": "",
        "index": 4,
        "text": "Cause I didn't know, \nthe winter cold would leave us moving so slow\nAnd will summer bring the feeling back to our toes?\nUntil it does can I just make it through?"
      },
      {
        "label": "",
        "index": 5,
        "text": "Cause I don't know where I've gone wrong\nAnd I can't find where I belong without you\nMake it through"
      },
      {
        "label": "",
        "index": 6,
        "text": "Cause I don't know where I've gone wrong\nAnd I can't find where I belong\nOh I can't find where I belong\nWithout you"
      }
    ],
    "id": "sherwood-make-it-through",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "Maybe This Time",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Waiting for a sign,\nI used to search with every step\nAnd even as a boy,\nso many things I can't forget\nSo if I made a list\nof everyone I've kissed\nWhat would that accomplish?"
      },
      {
        "label": "",
        "index": 1,
        "text": "I've heard it's said\nThat I won't take a chance\nBut everything I've held\nslipped through my hands"
      },
      {
        "label": "",
        "index": 2,
        "text": "Have I gone blind? Maybe this time\nYou'll give it a try, Maybe this time"
      },
      {
        "label": "",
        "index": 3,
        "text": "And now I know myself a little more and I confess\nThat even as a man, that I fear would get the best of me\nSo if I made a list of every chance I've missed\nWhat would that accomplish?"
      },
      {
        "label": "",
        "index": 4,
        "text": "Now I've been making eyes at you all night\nTrying to decide what's on your mind"
      },
      {
        "label": "",
        "index": 5,
        "text": "What's on your mind? Maybe this time\nYou'll give it a try, Maybe this time"
      },
      {
        "label": "",
        "index": 6,
        "text": "I saw the night, turn into day\nI saw the sun show his tired face\nShine in to me, shine on to mine\nCome see the tree shadows hang and cry"
      },
      {
        "label": "",
        "index": 7,
        "text": "Hello"
      }
    ],
    "id": "sherwood-maybe-this-time",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "Song In My Head",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Oh I don't believe it,\nThat I could be so deceiving\nAnd bringing you down to feel this lack of loyalty."
      },
      {
        "label": "",
        "index": 1,
        "text": "You were a song in my head,\nThe warmth of the sheets in my bed.\nA story forever told, but never old,\nA warm arrival never left so cold."
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "Don't blink, don't close your eyes,\nBut most of all don't apologize.\nIt's me who's got the demons to wrestle now."
      },
      {
        "label": "",
        "index": 3,
        "text": "Oh I don't believe it,\nThat I could be so deceiving\nAnd bringing you down to feel this lack of loyalty."
      },
      {
        "label": "",
        "index": 4,
        "text": "You were a song in my head,\nThe warmth of the sheets in my bed.\nA story forever told, but never old,\nA warm arrival never left so cold."
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "Don't blink, don't close your eyes,\nBut most of all don't apologize.\nIt's me who's got the demons to wrestle now."
      },
      {
        "label": "",
        "index": 6,
        "text": "There's a patch of blue in the stormy sky,\nA memory of a brighter time.\nWhen everything was new, and less watered down,\nBefore the summer turned to brown."
      },
      {
        "label": "",
        "index": 7,
        "text": "Don't say you can't believe it now,\nThat you're almost settled down,\n'Cause you'll be right here,\nWhen I come back around."
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "Don't blink, don't close your eyes,\nBut most of all don't apologize.\nIt's me who's got the demons to wrestle now."
      }
    ],
    "id": "sherwood-song-in-my-head",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "The Simple Life",
    "entries": [
      {
        "label": "Verse 1",
        "index": 0,
        "text": "This is the easy life\nThis is the simple life baby\nAnd you may be just what I\nHave always been searching for\nA light through an open door darling\nBut you're guarding it all night"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "I will whisper a song in your ear\nOh the things you've been missing my dear\nIf you remember the tune\nIt's all I can do\nJust remember I wrote it for you"
      },
      {
        "label": "Verse 2",
        "index": 2,
        "text": "And I carry an open box\nOf rusted and broken locks, slowly\nCan you show me where to lie?\nCause I'm tired as I can be\nMy legs aren't a part of me darling\nLet us go then, you and I\nThrough the night"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "I will whisper a song in your ear\nOh the things you've been missing my dear\nIf you remember the tune\nIt's all I can do\nJust remember I wrote it for you"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "I will whisper a song in your ear\nOh the things you've been missing my dear\nIf you remember the tune\nIt's all I can do\nJust remember I wrote it for you\nJust remember I wrote it for you"
      }
    ],
    "id": "sherwood-the-simple-life",
    "capo": 0,
    "chords": [
      {
        "label": "Verse",
        "value": "E C# F#m A B",
        "chords": [
          "E",
          "C#",
          "F#m",
          "A",
          "B"
        ]
      },
      {
        "label": "Chorus",
        "value": "A B E A B C# A Am E C# F# A B",
        "chords": [
          "A",
          "B",
          "E",
          "A",
          "B",
          "C#",
          "A",
          "Am",
          "E",
          "C#",
          "F#",
          "A",
          "B"
        ]
      }
    ]
  },
  {
    "artist": "Sherwood",
    "title": "The Town That You Live in",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "I must say, It's a nice day\nWhen the leaves start to turn,\nthere is so much to learn from the freeway\nYou're my friend, For the weekend\nWhen the spring comes around,\nyou can show me the town that you live in"
      },
      {
        "label": "",
        "index": 1,
        "text": "And tonight I walk through an empty street\nWith my shadow stretching in front of me\nWhen my lonely thoughts meet my lonely feet\nAnd the cold reminds me that I've chosen this life"
      },
      {
        "label": "",
        "index": 2,
        "text": "I must say, It's a nice day\nWith the flowers in bloom,\nthere is so much to view from the freeway\nAnd we're friends, For the weekend\nAnd when the fall comes around,\nyou can show me the town that you live in"
      },
      {
        "label": "",
        "index": 3,
        "text": "And tonight I walk through an empty street\nWith my shadow stretching in front of me\nWhen my lonely thoughts meet my lonely feet\nAnd the cold reminds me that I've"
      },
      {
        "label": "",
        "index": 4,
        "text": "So hang up the phone \n‘cause you're probably better alone"
      },
      {
        "label": "",
        "index": 5,
        "text": "And tonight I walk through an empty street\nWith my shadow stretching in front of me\nWhen my lonely thoughts meet my lonely feet\nAnd the cold reminds me that I've"
      },
      {
        "label": "",
        "index": 6,
        "text": "And tonight I walk through an empty street\nWith my shadow stretching in front of me\nWhen my lonely thoughts meet my lonely feet\nAnd the cold reminds me that I've chosen this life"
      }
    ],
    "id": "sherwood-the-town-you-live-in",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Sherwood",
    "title": "Worn",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Somedays the most that you hoped you do\nIs step in your leather shoes\nAnd make your way around the block\nDays that tired mother of ours\nAnd living has made her sad\nHow she wishes she could stop the clock"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "And so, I'm told, I wear, and I'm worn\nCome clean, come clean, come near, come home\nCome home"
      },
      {
        "label": "",
        "index": 2,
        "text": "You always come home to those empty rooms\nAnd wonder what's left to lose\nFolding sheets cause you can never stop\nDays that tired mother of ours\nAnd living has made her sad\nHow she wishes she could stop the clock"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "And so, I'm told, I wear, and I'm worn\nCome clean, come clean, come near, come home\nCome home"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "And so, I'm told, I wear, and I'm worn\nCome clean, come clean, come near, come home\nCome home"
      }
    ],
    "id": "sherwood-worn",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "The Beatles",
    "title": "Hey Jude",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Gunned down in the middle of things,\nthis thing between you and me\nI had no clue, and neither did you,\nof anything so unforeseen"
      },
      {
        "label": "",
        "index": 1,
        "text": "You're damn right, you can't fight\nthe need to be your own friend\nBut it gets so old, and you're so cold,\nfor the one that's waiting for you in the end\nSo come on"
      },
      {
        "label": "",
        "index": 2,
        "text": "Dumb luck, I guess I messed up,\ntelling you all where to go\nBut it seemed so right to try to cause a fight,\nI guess that goes to show what I know"
      },
      {
        "label": "",
        "index": 3,
        "text": "You're damn right, you can't fight\nthe need to be your own friend\nBut it gets so old, and you're so cold,\nfor the one that's waiting for you in the end\nSo I say, hell yes, I confess,\nto always wanting things to be the same\nBut this change you use as your excuse\nfor leaving me behind, well that's lame"
      },
      {
        "label": "",
        "index": 4,
        "text": "Gunned down in the middle of things,\nthis thing between you and me\nI had no clue, and neither did you,\nof anything so unforeseen"
      },
      {
        "label": "",
        "index": 5,
        "text": "You're damn right, you can't fight\nthe need to be your own friend\nBut it gets so old, and you're so cold,\nfor the one that's waiting for you in the end"
      },
      {
        "label": "",
        "index": 6,
        "text": "So I say, hell yes, I confess,\nto always wanting things to be the same\nBut this change you use as your excuse\nfor leaving me behind, well that's lame"
      },
      {
        "label": "",
        "index": 7,
        "text": "And it's too late, I guess that's just great,\ntelling me how things should stay\nBut the truth be known, I'd rather be alone\nthan to have to live like this another day"
      }
    ],
    "id": "the-beatles-hey-jude",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "The Beatles",
    "title": "In My Life",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "There are places I'll remember\nAll my life, though some have changed\nSome forever, not for better\nSome have gone and some remain\nAll these places had their moments\nWith lovers and friends, I still can recall\nSome are dead, and some are living\nIn my life, I've loved them all"
      },
      {
        "label": "",
        "index": 1,
        "text": "But of all these friends and lovers\nThere is no one compares with you\nAnd these memories lose their meaning\nWhen I think of love as something new"
      },
      {
        "label": "",
        "index": 2,
        "text": "Though I know I'll never lose affection\nFor people and things that went before\nI know I'll often stop and think about them\nIn my life, I love you more"
      },
      {
        "label": "",
        "index": 3,
        "text": "Though I know I'll never lose affection\nFor people and things that went before\nI know I'll often stop and think about them\nIn my life, I love you more"
      }
    ],
    "id": "the-beatles-in-my-life",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "The Beatles",
    "title": "Yesterday",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Yesterday\nAll my troubles seemed so far away\nNow it looks as though they're here to stay\nOh, I believe in yesterday"
      },
      {
        "label": "",
        "index": 1,
        "text": "Suddenly\nI'm not half the man I used to be\nThere's a shadow hangin' over me\nOh, yesterday came suddenly"
      },
      {
        "label": "",
        "index": 2,
        "text": "Why she had to go, I don't know, she wouldn't say\nI said something wrong, now I long for yesterday"
      },
      {
        "label": "",
        "index": 3,
        "text": "Yesterday\nLove was such an easy game to play\nNow I need a place to hide away\nOh, I believe in yesterday"
      },
      {
        "label": "",
        "index": 4,
        "text": "Why she had to go, I don't know, she wouldn't say\nI said something wrong, now I long for yesterday"
      },
      {
        "label": "",
        "index": 5,
        "text": "Yesterday\nLove was such an easy game to play\nNow I need a place to hide away\nOh, I believe in yesterday"
      }
    ],
    "id": "the-beatles-yesterday",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "The Clash",
    "title": "Spanish Bombs",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Spanish songs in Andalucia\nThe shooting sites in the days of '39"
      },
      {
        "label": "",
        "index": 1,
        "text": "Oh, please, leave the ventana open\nFredrico Lorca is dead and gone"
      },
      {
        "label": "",
        "index": 2,
        "text": "Bullet holes in the cemetery walls\nThe black cars of the Guardia Civil"
      },
      {
        "label": "",
        "index": 3,
        "text": "Spanish bombs on the Costa Rica\nI'm flying in on a DC 10 tonight"
      },
      {
        "label": "",
        "index": 4,
        "text": "Spanish bombs,\nyo te quiero infinito,\nyo te acuerda oh mi corazón"
      },
      {
        "label": "",
        "index": 5,
        "text": "Spanish weeks in my disco casino\nThe freedom fighters died upon the hill"
      },
      {
        "label": "",
        "index": 6,
        "text": "They sang the red flag\nThey wore the black one\nBut after they died it was Mockingbird Hill"
      },
      {
        "label": "",
        "index": 7,
        "text": "Back home the buses went up in flashes\nThe Irish tomb was drenched in blood"
      },
      {
        "label": "",
        "index": 8,
        "text": "Spanish bombs shatter the hotels\nMy senorita's rose was nipped in the bud"
      },
      {
        "label": "",
        "index": 9,
        "text": "Spanish bombs,\nyo te quiero infinito,\nyo te acuerda oh mi corazón"
      },
      {
        "label": "",
        "index": 10,
        "text": "The hillsides ring with \"Free the people\"\nOr can I hear the echo from the days of '39?"
      },
      {
        "label": "",
        "index": 11,
        "text": "With trenches full of poets\nThe ragged army, fixin' bayonets to fight the other line"
      },
      {
        "label": "",
        "index": 12,
        "text": "Spanish bombs rock the province\nI'm hearing music from another time"
      },
      {
        "label": "",
        "index": 13,
        "text": "Spanish bombs on the Costa Brava\nI'm flying in on a DC 10 tonight"
      },
      {
        "label": "",
        "index": 14,
        "text": "Spanish bombs,\nyo te quiero infinito,\nyo te acuerda oh mi corazón"
      },
      {
        "label": "",
        "index": 15,
        "text": "Spanish songs in Andalucia,\nMandolina, oh mi corazon"
      },
      {
        "label": "",
        "index": 16,
        "text": "Spanish songs in Granada,\noh mi corazon"
      }
    ],
    "id": "the-clash-spanish-bombs",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "Amends",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Suffocating every breath you take\nBuilding up a tower just to break\nHold my breath till I turn blue\nSincerity's the former strongest suit"
      },
      {
        "label": "",
        "index": 1,
        "text": "Ordinary circumstances that\nCould never be seen from where you are\nIn a room as small as this\nI didn't think that you could seem so far"
      },
      {
        "label": "",
        "index": 2,
        "text": "So I make my rounds to the lower ground\nWith a message late to send\nI don't think it's worth the time\nTo make amends"
      },
      {
        "label": "",
        "index": 3,
        "text": "Winter comes and goes without a word\nSpring quiets the bad things that I heard\nSummer is a happy time\nMost certainly the fall will divide"
      },
      {
        "label": "",
        "index": 4,
        "text": "So you operate with an open heart\nYou forgot to stitch again\nI don't think it's worth the time\nTo make amends"
      }
    ],
    "id": "tony-sly-amends",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "Capo, 4th Fret",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Like a child I can sit in the corner\nWith these pills I can barely get by\nSo I wait for the hour of approval\nTen minutes until it's five"
      },
      {
        "label": "",
        "index": 1,
        "text": "And it's Friday, so I don't feel so lonely\nI know the wolves are out tonight\nThe sound of ice in a bottomless glass\nTells me that all that is fine"
      },
      {
        "label": "",
        "index": 2,
        "text": "Playing guitar and I'm forgetting words\nLike a baby who just learned to speak\nUnfinished songs that I swear I'd get done\nBut the premise was just kind of weak"
      },
      {
        "label": "",
        "index": 3,
        "text": "Like the moon you are cold, you are distant\nLike the sun I can burn through the day\nIn the valley the echo gets longer\nThey've all gone away"
      },
      {
        "label": "",
        "index": 4,
        "text": "Playing guitar and I'm forgetting words\nLike a baby who just learned to speak\nUnfinished songs that I swear I'd get done\nBut the premise was just kind of weak"
      },
      {
        "label": "",
        "index": 5,
        "text": "There is time to reflect and ponder\nAs the chips fall where they may\nI will live for the sake of others\nSo they can do the same"
      }
    ],
    "id": "tony-sly-capo-4th-fret",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "Exit",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Yesterday becomes tomorrow leaves you wanting more\nSo intricate becomes the bathroom floor\nAnd even though you're in a ditch you dig a deeper hole\nI'm a shovel cause it's out of my control"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "Promised a change, I didn't hear a word from you\nDon't hold me back\nThe exit isn't somewhere in my heart\nA promise of pain... we're back to the start"
      },
      {
        "label": "",
        "index": 2,
        "text": "The reason that I still persist it's obvious I care\nBeen waiting by a door with no one there\nIt seems like you are locked inside my key don't fit no more\nAnd you're walking on the land mines you laid down before"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "Promised a change I didn't hear a word from you\nDon't hold me back\nThe exit isn't somewhere in my heart\nA promise of pain\nWe're back to the start"
      }
    ],
    "id": "tony-sly-exit",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "Flying South",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "If I could sleep tonight then we wouldn't have to fight\nAbout nothing going right so tell me what to do"
      },
      {
        "label": "",
        "index": 1,
        "text": "Should I write you a song about two minutes long?\nNot enough time to explain may be enough for you"
      },
      {
        "label": "Chorus",
        "index": 2,
        "text": "You see that I am flying south for the winter once again\nI feel like I am bailing out on my only friend"
      },
      {
        "label": "",
        "index": 3,
        "text": "Aside from all the things we do to make each other blue\nCan you forgive me again? I am the one to blame"
      },
      {
        "label": "",
        "index": 4,
        "text": "You've been there all the time, I’m a missing person sign\nBut I hope that we can find all that we have misplaced"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "You see that I am flying south for the winter once again\nI feel like I am bailing out on my only friend\nI am like the wind in your sinking sailboat\nStuck at the dock without you"
      },
      {
        "label": "",
        "index": 6,
        "text": "If I could sleep tonight then we wouldn't have to fight\nAbout nothing going right so tell me what to do\nPlease tell me what to do"
      }
    ],
    "id": "tony-sly-flying-south",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "On The Outside",
    "entries": [
      {
        "label": "Verse",
        "index": 0,
        "text": "I'll see you on the outside and we'll never be the same\nAfter all that's happened what is it that we've gained?\nWell I've made mistakes before and never lived them down\nAfter all you've done for me I'm bound (don't say another word)"
      },
      {
        "label": "Chorus",
        "index": 1,
        "text": "To see you on the outside where we can both be sane\n(You're speaking for yourself)\nI'm bleeding on the inside a place you might have been\nBut now I know I'll (never be the same)"
      },
      {
        "label": "Verse",
        "index": 2,
        "text": "Well I heard you got a place and it's far away from here\nReality has sunken in now confirming all my fears\nI'm not gonna track you down and it's too late to stop\nThinking 'bout the time that we have lost (I'm happy without you)"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "I'm trying to forget you and everything we had\n(We didn't stand a chance)\nWe're miserable and it's true I haven't learned a thing\nBut now I know I'll trust no one else"
      },
      {
        "label": "Bridge",
        "index": 4,
        "text": "There was a time when we were back and forth, it wasn't easy\nI guess that we're back where we belong\nOne thing is for sure, it wasn't strong"
      },
      {
        "label": "Chorus",
        "index": 5,
        "text": "I'll see you on the outside where we can both feel shame\n(You've never been more right)\nI'm dying on the inside, you're never coming back\nAnd now I know whatever we go through\nMy heart is stuck with you"
      }
    ],
    "id": "tony-sly-on-the-outside",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "Second Act (End Credits)",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "It's time to say goodnight\nWherever that you are\nI'm sure it's getting late\nThe party comes apart"
      },
      {
        "label": "",
        "index": 1,
        "text": "Remember where you've been, \nSo you can make it back\nIt's time now to start the second act"
      },
      {
        "label": "",
        "index": 2,
        "text": "Losing all control while perfectly engaged\nIn conversations that won't exist by day"
      },
      {
        "label": "",
        "index": 3,
        "text": "By your side they wait \nBut you're not coming down\nEarth to you, you left them on the ground"
      },
      {
        "label": "",
        "index": 4,
        "text": "Surrounded by a nice inflated comfort\nOutside of which they suffer\nDenial takes the stage\nThe curtains open to reveal a ghost of you\nIt's time to take a bow and float away"
      },
      {
        "label": "",
        "index": 5,
        "text": "Calling the police just figuratively\nIt's like you're in the mob \nUntouchable and mean"
      },
      {
        "label": "",
        "index": 6,
        "text": "As paranoia builds a fortress made of clay\nIt's morning and the sun will have it's way"
      },
      {
        "label": "",
        "index": 7,
        "text": "Surrounded by a nice inflated comfort\nOutside of which they suffer\nDenial takes the stage\nThe curtains open to reveal a ghost of you\nIt's time to take a bow and float away"
      },
      {
        "label": "",
        "index": 8,
        "text": "It's time to say goodnight\nWherever that you are\nI'm sure it's getting late\nThe party comes apart"
      }
    ],
    "id": "tony-sly-second-act-end-credits",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "Shortest Pier",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Put my eyes where I can see\nWhere the soul used to be\nI feel the walls closing in on me"
      },
      {
        "label": "",
        "index": 1,
        "text": "Shut my mouth I take it back\nIt's no way for us to act\nI missed the boat but somehow sailed away"
      },
      {
        "label": "",
        "index": 2,
        "text": "But that's okay\nI'll have a talk\nWith this dive bar\nOn a long walk\nAlong the shortest pier\nAnd watch it disappear"
      },
      {
        "label": "",
        "index": 3,
        "text": "There's a hole in my mind\nWhere the sun used to shine\nMemories in black and white"
      },
      {
        "label": "",
        "index": 4,
        "text": "Cut me off I've had enough\nInfect the wound and sew me up\nNo more words, Hmm, hmm, hmm"
      },
      {
        "label": "",
        "index": 5,
        "text": "But that's okay\nI'll have a talk\nWith this dive bar\nOn a long walk\nAlong the shortest pier\nAnd watch it disappear"
      }
    ],
    "id": "tony-sly-shortest-pier",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Tony Sly",
    "title": "Soulmate",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Monday morning you woke up crying\nThe outcome of a stupid line oh-oh\nSaid by some asshole on the street\nIt wasn't me, it wasn't you\nIt wasn't anybody you knew is it\nYour old friend insecurity?"
      },
      {
        "label": "",
        "index": 1,
        "text": "The one companion that stuck by your side\nsince you were born"
      },
      {
        "label": "",
        "index": 2,
        "text": "Tuesday night you were in a fight\nThe same old argument black and white\nIt's something that's eating your inside\nIt's not the food, it's not the booze\nIt isn't anything consumed so is it\nbecoming a problem you can't hide?"
      },
      {
        "label": "",
        "index": 3,
        "text": "So every time you thought that you were wrong\nyou were right"
      },
      {
        "label": "",
        "index": 4,
        "text": "The devil on your shoulder \ngives you strength in paranoia\nThe countless times you called a few\nYour best friend till the end of time\nThey'll always be there by your side\nSolidify conditions you've abused\nWhat that means you'll never have a clue"
      },
      {
        "label": "",
        "index": 5,
        "text": "More than just a life long friend\nNo social life I guess we can pretend\nIt's not like I care but anyway\nYou always wanna leave, you never wanna stay\nCause you think if you drink too much\nthen you will lose it\nYou'll drink your security away"
      },
      {
        "label": "",
        "index": 6,
        "text": "The one companion that stuck by your side\nsince you were born"
      },
      {
        "label": "",
        "index": 7,
        "text": "The little voice inside your head,\nannoying and confusing\nThat somehow tells you what to do\nSocially you've lost your mind\nand basically you're out of time\nYou try to build so much it breaks on you\nWhat that means you'll never have a clue."
      },
      {
        "label": "",
        "index": 8,
        "text": "What that means you'll never have \nWhat that means you'll never have \nWhat that means you'll never have a clue."
      }
    ],
    "id": "tony-sly-soulmate",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "We Shot The Moon",
    "title": "Sway Your Head",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Sway your head, move your feet,\nwake yourself from the sleep\nIf there's a day, there's a way,\nyou can get yourself there\nGolden smile, you got style\nthat they can't take away"
      },
      {
        "label": "",
        "index": 1,
        "text": "Scream and shout, get it out \nbefore it swallows you\nNo surprise in these times\nhow it really gets through\nAll the walks and the thoughts\nbut can you break through?"
      },
      {
        "label": "",
        "index": 2,
        "text": "You're alive, So alive, Now c'mon"
      },
      {
        "label": "Chorus",
        "index": 3,
        "text": "You gotta pull yourself back together\nGive it one more shot\nIt's now or never\nWith the new day falling for you\nYou gotta believe\n(Woah)"
      },
      {
        "label": "",
        "index": 4,
        "text": "Build a plan, yes you can\nYou've got nothing to lose\nLook around, up and down\nWhat are you gonna choose?\nCan you see the sunrise\nThat came up for you?"
      },
      {
        "label": "",
        "index": 5,
        "text": "You're alive, So alive, Now c'mon"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "You gotta pull yourself back together\nGive it one more shot\nIt's now or never\nWith the new day calling for you\nYou gotta believe\n(Woah)"
      },
      {
        "label": "Chorus",
        "index": 7,
        "text": "You gotta pull yourself, back in the water\nTake in one deep breath\nYou're getting closer\nAfter all you've talked and seen\nYou gotta believe\n(Woah)"
      },
      {
        "label": "",
        "index": 8,
        "text": "I think it's coming back\nI think it's coming back\nI think it's coming back to me"
      },
      {
        "label": "",
        "index": 9,
        "text": "Now I, I feel I'm getting better\nNow I, my world is getting better"
      },
      {
        "label": "Chorus",
        "index": 10,
        "text": "You gotta pull yourself back together\nGive it one more shot\nIt's now or never\nWith the new day calling for you\nYou gotta believe\n(Woah)"
      },
      {
        "label": "Chorus",
        "index": 11,
        "text": "You gotta pull yourself, back in the water\nTake in one deep breath\nYou're getting closer\nAfter all you've talked and seen\nYou gotta believe\n(Woah)"
      }
    ],
    "id": "we-shot-the-moon-sway-your-head",
    "capo": 0,
    "chords": []
  },
  {
    "artist": "Weezer",
    "title": "Say Ain’t So",
    "entries": [
      {
        "label": "",
        "index": 0,
        "text": "Oh yeah, Alright"
      },
      {
        "label": "",
        "index": 1,
        "text": "Somebody's Heine’ is crowdin' my icebox\nSomebody's cold one is givin' me chills\nGuess I'll just close my eyes"
      },
      {
        "label": "",
        "index": 2,
        "text": "Oh yeah, Alright\nFeels good inside"
      },
      {
        "label": "",
        "index": 3,
        "text": "Flip on the tele, wrestle with Jimmy\nSomething is bubbling behind my back\nThe bottle is ready to blow"
      },
      {
        "label": "Chorus",
        "index": 4,
        "text": "Say it ain't so, your drug is a heartbreaker\nSay it ain't so, my love is a life taker"
      },
      {
        "label": "",
        "index": 5,
        "text": "I can't confront you, I never could do\nThat which might hurt you\nSo try and be cool\nWhen I say\nThis way is a waterslide away from me\nThat takes you further every day\nSo be cool"
      },
      {
        "label": "Chorus",
        "index": 6,
        "text": "Say it ain't so, your drug is a heartbreaker\nSay it ain't so, my love is a life taker"
      },
      {
        "label": "Bridge",
        "index": 7,
        "text": "Dear Daddy\nI write you in spite of years of silence\nYou've cleaned up, found Jesus, things are good or so I hear\nThis bottle of Steven's awakens ancient feelings\nLike father, stepfather, the son is drowning in the flood\nYeah, yeah, yeah, yeah"
      },
      {
        "label": "Chorus",
        "index": 8,
        "text": "Say it ain't so, your drug is a heartbreaker\nSay it ain't so, my love is a life taker"
      }
    ],
    "id": "weezer-say-aint-so",
    "capo": 0,
    "chords": []
  }
];
